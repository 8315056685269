import AppLocale from '../../lngProvider/ja-JP';
import NotificationError from '../notifications/NotificationError';

export function CustomHandleError(error: any) {
  const locale = localStorage.getItem('locale') || 'ja';
  const { messages } = AppLocale[`${locale}`];

  if (error.statusCode === 500 || error.statusCode === 501) {
    NotificationError({
      content: messages[`error.500`],
    });
  } else if (error.statusCode === 403) {
    NotificationError({
      content: messages[`error.403`],
    });
  } else if (error.statusCode === 401) {
    NotificationError({
      content: messages[`error.401`],
    });
  } else if (error.statusCode === 404) {
    NotificationError({
      content: messages[`error.404`],
    });
  } else if (error.statusCode === 406) {
    NotificationError({
      content: messages[`error.406`],
    });
  } else {
    const errorMessage = (error.message || '').replace(/\s/g, '_').toUpperCase();
    const errorContent = messages[`error.${errorMessage}`]
      ? messages[`error.${errorMessage}`]
      : messages[`error.common`];

    // console.log('errorMessage', errorMessage);
    if (errorMessage) {
      NotificationError({
        content: errorContent,
      });
    } else {
      NotificationError({
        content: messages[`error.common`],
      });
    }
  }
}
