import React from 'react';

export const SvgPrintIcon = () => (
  <span>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4707 15.6666H22.3333C23.5907 15.6666 24.2187 15.6666 24.6093 15.2759C25 14.8853 25 14.2573 25 12.9999V11.6666C25 9.15192 25 7.89592 24.2187 7.11459C23.4373 6.33325 22.1813 6.33325 19.6667 6.33325H6.33333C3.81867 6.33325 2.56267 6.33325 1.78133 7.11459C1 7.89592 1 9.15192 1 11.6666V14.3333C1 14.9613 1 15.2759 1.19467 15.4719C1.39067 15.6666 1.70667 15.6666 2.33333 15.6666H4.52933"
        stroke="#1C86ED"
        strokeWidth="1.5"
      />
      <path
        d="M21 6.33333V4.84C21 3.496 21 2.824 20.7387 2.31067C20.5085 1.85882 20.1412 1.49147 19.6893 1.26133C19.176 1 18.504 1 17.16 1H8.84C7.496 1 6.824 1 6.31067 1.26133C5.85882 1.49147 5.49147 1.85882 5.26133 2.31067C5 2.824 5 3.496 5 4.84V6.33333M5 24.0747V13C5 11.7427 5 11.1147 5.39067 10.724C5.78133 10.3333 6.40933 10.3333 7.66667 10.3333H18.3333C19.5907 10.3333 20.2187 10.3333 20.6093 10.724C21 11.1147 21 11.7427 21 13V24.0747C21 24.4973 21 24.708 20.8613 24.808C20.7227 24.908 20.5227 24.8413 20.1227 24.708L17.2107 23.7373C17.1067 23.7027 17.0547 23.684 17 23.684C16.9467 23.684 16.8933 23.7027 16.7893 23.7373L13.2107 24.9293C13.1067 24.964 13.0547 24.9827 13 24.9827C12.9467 24.9827 12.8933 24.964 12.7893 24.9293L9.21067 23.7373C9.10667 23.7027 9.05467 23.684 9 23.684C8.94667 23.684 8.89333 23.7027 8.78933 23.7373L5.87733 24.708C5.47733 24.8413 5.27733 24.908 5.13867 24.808C5 24.708 5 24.4973 5 24.0747Z"
        stroke="#1C86ED"
        strokeWidth="1.5"
      />
      <path
        d="M10.3333 15.6667H14.3333M10.3333 19.6667H16.3333"
        stroke="#1C86ED"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </span>
);
