import antdJP from 'antd/locale/ja_JP';
import common from './common_ja.json';
import signin from './signin.json';
import notification from './notify.json';
import profile from './profile.json';
import chat from './chat-support.json';
import payment from './payment-setting.json';
import rack from './rack.json';
import warehouse from './warehouse.json';
import itemManagement from './itemManagement.json';
import menu from './menu.json';
import error from './error.json';
import validate from './validate.json';
import requestmanagement from './request_management.json';
import chatItem from './chat-item.json';
import detailItem from './detailItem.json';
import viewStatus from './viewStatus.json';
import requestShipping from './requestShipping.json';
import shipment from './shipment.json';
import storage from './storage.json';
import customerInformation from './customerInformation.json';

const jpLang = {
  messages: {
    ...common,
    ...signin,
    ...signin,
    ...notification,
    ...profile,
    ...chat,
    ...payment,
    ...rack,
    ...warehouse,
    ...itemManagement,
    ...menu,
    ...error,
    ...validate,
    ...requestmanagement,
    ...chatItem,
    ...detailItem,
    ...viewStatus,
    ...requestShipping,
    ...shipment,
    ...storage,
    ...customerInformation,
  },
  antd: antdJP,
  locale: 'ja-JP',
};
export default jpLang;
