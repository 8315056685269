import React from 'react';

export const SvgPencil = () => (
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 6.14853L18 9.1167M13 20H21M5 16.0424L4 20L8 19.0106L19.586 7.54753C19.9609 7.17646 20.1716 6.67324 20.1716 6.14853C20.1716 5.62383 19.9609 5.12061 19.586 4.74954L19.414 4.57936C19.0389 4.2084 18.5303 4 18 4C17.4697 4 16.9611 4.2084 16.586 4.57936L5 16.0424Z"
        stroke="#514B4D"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default SvgPencil;
