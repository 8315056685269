import React from 'react';

export const SvgSend = () => (
  <span>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.68487 16L6.96315 9.50333C6.75643 7.64406 8.67067 6.27828 10.3615 7.08006L24.6334 13.8408C26.4557 14.7036 26.4557 17.2965 24.6334 18.1592L10.3615 24.9212C8.67067 25.7218 6.75643 24.3572 6.96315 22.4979L7.68487 16ZM7.68487 16H16.0492"
        stroke="#1C86ED"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default SvgSend;
