const SvgIconEditLarge = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#499EF1" />
    <path
      d="M23 13.9997L26 16.9997M21 27.9997H29M13 23.9997L12 27.9997L16 26.9997L27.586 15.4137C27.9609 15.0386 28.1716 14.53 28.1716 13.9997C28.1716 13.4694 27.9609 12.9608 27.586 12.5857L27.414 12.4137C27.0389 12.0388 26.5303 11.8281 26 11.8281C25.4697 11.8281 24.9611 12.0388 24.586 12.4137L13 23.9997Z"
      stroke="#514B4D"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgIconEditLarge;
