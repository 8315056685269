import { Input, InputProps } from 'antd';
import { useIntl } from 'react-intl';
import './index.scss';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

interface CustomInputProps extends InputProps {
  placeholder?: string;
  className?: string;
  isPassword?: boolean;
  width?: number;
  height?: number;
}

const CustomInput = (props: CustomInputProps) => {
  const intl = useIntl();
  const { placeholder, className, isPassword, width, height, ...restProps } = props;

  const inputStyle = { height, width };

  return !isPassword ? (
    <Input
      style={inputStyle}
      maxLength={255}
      placeholder={placeholder || undefined}
      className={`ant-custom-input ${className}`}
      {...restProps}
    />
  ) : (
    <Input.Password
      style={inputStyle}
      maxLength={255}
      placeholder={placeholder || undefined}
      className={`ant-custom-input ${className}`}
      autoComplete="new-password"
      iconRender={(visible) =>
        visible ? <EyeInvisibleOutlined style={{ fontSize: '24px' }} /> : <EyeOutlined style={{ fontSize: '24px' }} />
      }
      {...restProps}
    />
  );
};

CustomInput.TextArea = Input.TextArea;
export default CustomInput;
