import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { useIntl } from 'react-intl';
import './customSelect.scss';
import SvgSelect from '../@svg/SvgSelect';
import React from 'react';
import { NamePath } from 'antd/es/form/interface';

interface CustomSelectProps {
  isForm?: boolean;
  name?: NamePath;
  label?: React.ReactNode;
  selectProps?: SelectProps;
  formItemProps?: FormItemProps;
}

const CustomSelect = (props: CustomSelectProps) => {
  const intl = useIntl();
  const { isForm, name, label, selectProps, formItemProps } = props;

  return isForm ? (
    <Form.Item
      name={name}
      label={label}
      {...formItemProps}
      className={`custom-select__form-container ${formItemProps.className ?? ''}`}
    >
      <Select
        allowClear
        {...selectProps}
        suffixIcon={<SvgSelect />}
        className={`custom-select__container ${selectProps?.className}`}
        popupClassName={`custom-select-popup__container ${selectProps?.popupClassName}`}
      />
    </Form.Item>
  ) : (
    <Select
      allowClear
      {...selectProps}
      showSearch={false}
      suffixIcon={<SvgSelect />}
      className={`custom-select__container ${selectProps?.className}`}
      popupClassName={`custom-select-popup__container ${selectProps?.popupClassName}`}
    />
  );
};
export default CustomSelect;
