import { Rule } from 'antd/lib/form';
import { IntlShape } from 'react-intl';
import { _validator } from './validator.validate';

interface Validate {
  [key: string]: Rule[];
}

export const ValidateLibrary: (intl: IntlShape, _option?: any[]) => Validate = (intl, _option?) => {
  if (!_option) {
    _option = [];
  }

  const email = [
    {
      validator: _validator({
        required: intl.formatMessage({ id: 'validate.required' }),
        email: intl.formatMessage({ id: 'validate.email' }),
      }),
    },
    ..._option,
  ];

  const password = [
    {
      validator: _validator({
        required: intl.formatMessage({ id: 'validate.required' }),
        space: 'このフィールドにはスペースは含まれません',
        isLength: { min: 8, max: 30, message: intl.formatMessage({ id: 'validate.isLength' }) },
        emoj: intl.formatMessage({ id: 'validate.emoj' }),
      }),
    },
    ..._option,
  ];

  const required = [
    {
      validator: _validator({
        required: intl.formatMessage({ id: 'validate.required' }),
      }),
    },
    ..._option,
  ];

  const phone = [
    {
      validator: _validator({
        required: intl.formatMessage({ id: 'validate.required' }),
        phone: intl.formatMessage({ id: 'validate.phone' }),
      }),
    },
    ..._option,
  ];

  const loginId = [
    {
      validator: _validator({
        required: intl.formatMessage({ id: 'validate.required' }),
        loginId: intl.formatMessage({ id: 'validate.loginId' }),
      }),
    },
    ..._option,
  ];

  const nameFull = [
    {
      validator: _validator({
        required: '名前を入力してください',
        nameFull: '正しい名前形式を入力してください',
      }),
    },
    ..._option,
  ];

  const rackName = [
    {
      validator: _validator({
        required: intl.formatMessage({ id: 'validate.requiredRackName' }),
        rackName: intl.formatMessage({ id: 'rack.validateName' }),
      }),
    },
  ];

  const postCode = [
    {
      validator: _validator({
        required: intl.formatMessage({ id: 'validate.required' }),
        postCode: intl.formatMessage({ id: 'validate.postCode' }),
      }),
    },
  ];

  return {
    email,
    password,
    required,
    phone,
    loginId,
    nameFull,
    rackName,
    postCode,
  };
};
