import './index.scss';
import { TButtonCustom } from './interface/interface';

const ButtonResponding = ({ value, ...props }: TButtonCustom) => {
  return (
    <button className="button-repsponding" onClick={props.onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <g clipPath="url(#clip0_19098_113600)">
          <path
            d="M5.16667 0.666748H11.8333V2.00008H14.5V8.00008H13.1667V3.33341H11.8333V4.66675H5.16667V3.33341H3.83333V14.0001H8.5V15.3334H2.5V2.00008H5.16667V0.666748ZM6.5 3.33341H10.5V2.00008H6.5V3.33341ZM13.8333 9.33342V12.0001H16.5V13.3334H13.8333V16.0001H12.5V13.3334H9.83333V12.0001H12.5V9.33342H13.8333Z"
            fill="#FFB021"
          />
        </g>
        <defs>
          <clipPath id="clip0_19098_113600">
            <rect width="16" height="16" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
      <p>{value}</p>
    </button>
  );
};
export default ButtonResponding;
