import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import './index.scss';

interface CustomTabsProps extends TabsProps {
  className?: string;
}

const CustomeTabs = (props: CustomTabsProps) => {
  const { className, ...restProps } = props;
  return <Tabs defaultActiveKey="1" className={`ant-custom-tabs ${className}`} {...restProps} />;
};

export default CustomeTabs;
