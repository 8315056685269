import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ADMIN_ROUTE_PATH } from '../../constants/router';
import { LOCAL_STORAGE, SESSION_STORAGE } from '../../constants/storage';
import { socketConfig } from '../../webSocket/webSocket.config';
import { ChannelRegister } from '../../webSocket/webSocket.constants';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from '../../util/constants';

const AuthRoute = () => {
  const queryClient = useQueryClient();
  const isLoggedIn = Boolean(
    localStorage.getItem(LOCAL_STORAGE.TOKEN) || sessionStorage.getItem(SESSION_STORAGE.TOKEN),
  );

  useEffect(() => {
    socketConfig.on(ChannelRegister.JOIN_CONVERSATION, (data) => {
      const parseData = JSON.parse(data);

      if (parseData?.content?.id) {
        socketConfig.emit(ChannelRegister.JOIN_CONVERSATION, parseData?.content?.id);
      }
    });

    socketConfig.on(ChannelRegister.SEND_MESSAGE, (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.COUNT_UNREAD_GROUP_CHAT],
        stale: true,
      });
    });

    return () => {
      // socketConfig.off(ChannelRegister.JOIN_CONVERSATION);
      socketConfig.off(ChannelRegister.SEND_MESSAGE);
    };
  }, []);
  return (
    <>
      {!isLoggedIn && <Navigate to={ADMIN_ROUTE_PATH.SIGNIN} />}
      <Outlet />
    </>
  );
};

export default AuthRoute;
