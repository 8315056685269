import { Button, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ReactPlayerProps } from 'react-player/lazy';
import './index.scss';
import VideoThumbnail from 'react-video-thumbnail';

interface VideoModalInterface extends ReactPlayerProps {
  privew: React.ReactNode;
}

const VideoModal = (props: VideoModalInterface) => {
  const { privew, ...restProps } = props;
  console.log(restProps);
  const [open, setOpen] = useState<boolean>(false);
  const [play, setPlay] = useState<boolean>(false);
  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        <VideoThumbnail
          videoUrl={restProps.url}
          // thumbnailHandler={(thumbnail) => console.log(thumbnail)}
          snapshotAtTime={1}
        />
      </div>
      <Modal
        centered
        className="video-modal-custom"
        title={''}
        footer={[]}
        open={open}
        onCancel={() => {
          setPlay(false);
          setTimeout(() => {
            setOpen(false);
          }, 100);
        }}
      >
        <ReactPlayer
          controls
          {...restProps}
          playing={play}
          onPlay={() => {
            setPlay(!play);
          }}
        />
      </Modal>
      <span onClick={() => setOpen(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M7.01123 11.8509V6.14908C7.01135 6.07774 7.03065 6.00775 7.06712 5.94643C7.10359 5.88512 7.15588 5.83474 7.2185 5.80058C7.28113 5.76642 7.3518 5.74973 7.42309 5.75227C7.49439 5.7548 7.56369 5.77646 7.62373 5.81499L12.0592 8.6651C12.1153 8.70109 12.1616 8.75062 12.1936 8.80914C12.2256 8.86766 12.2423 8.93329 12.2423 8.99999C12.2423 9.06669 12.2256 9.13232 12.1936 9.19084C12.1616 9.24936 12.1153 9.29889 12.0592 9.33488L7.62373 12.1858C7.56369 12.2243 7.49439 12.246 7.42309 12.2485C7.3518 12.251 7.28113 12.2344 7.2185 12.2002C7.15588 12.166 7.10359 12.1157 7.06712 12.0543C7.03065 11.993 7.01135 11.923 7.01123 11.8517V11.8509Z"
            fill="white"
          />
          <path
            d="M0.25 9C0.25 4.16761 4.16761 0.25 9 0.25C13.8324 0.25 17.75 4.16761 17.75 9C17.75 13.8324 13.8324 17.75 9 17.75C4.16761 17.75 0.25 13.8324 0.25 9ZM9 1.44318C6.99581 1.44318 5.0737 2.23934 3.65652 3.65652C2.23934 5.0737 1.44318 6.99581 1.44318 9C1.44318 11.0042 2.23934 12.9263 3.65652 14.3435C5.0737 15.7607 6.99581 16.5568 9 16.5568C11.0042 16.5568 12.9263 15.7607 14.3435 14.3435C15.7607 12.9263 16.5568 11.0042 16.5568 9C16.5568 6.99581 15.7607 5.0737 14.3435 3.65652C12.9263 2.23934 11.0042 1.44318 9 1.44318Z"
            fill="white"
          />
        </svg>
      </span>
    </>
  );
};

export default VideoModal;
