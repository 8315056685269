import React from 'react';

export const SvgNotMatch = () => (
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 11.7183C8.14556 11.7183 8.26722 11.6692 8.365 11.5708C8.46333 11.4725 8.5125 11.3506 8.5125 11.205C8.5125 11.06 8.46333 10.9383 8.365 10.84C8.26722 10.7417 8.14556 10.6925 8 10.6925C7.85444 10.6925 7.73278 10.7417 7.635 10.84C7.53722 10.9383 7.48806 11.06 7.4875 11.205C7.48694 11.35 7.53611 11.4719 7.635 11.5708C7.73278 11.6692 7.85444 11.7183 8 11.7183ZM7.58333 8.96167H8.41667V3.96167H7.58333V8.96167ZM8.0025 15.5C6.96583 15.5 5.99083 15.3033 5.0775 14.91C4.16472 14.5161 3.37056 13.9817 2.695 13.3067C2.01944 12.6317 1.48472 11.8383 1.09083 10.9267C0.696945 10.015 0.5 9.04028 0.5 8.0025C0.5 6.96472 0.696667 5.98972 1.09 5.0775C1.48333 4.16528 2.01778 3.37111 2.69333 2.695C3.36889 2.01889 4.16222 1.48417 5.07333 1.09083C5.98444 0.697501 6.95917 0.500557 7.9975 0.500001C9.03583 0.499446 10.0108 0.696112 10.9225 1.09C11.8342 1.48389 12.6283 2.01833 13.305 2.69333C13.9817 3.36833 14.5164 4.16167 14.9092 5.07333C15.3019 5.985 15.4989 6.95972 15.5 7.9975C15.5011 9.03528 15.3044 10.0103 14.91 10.9225C14.5156 11.8347 13.9811 12.6289 13.3067 13.305C12.6322 13.9811 11.8389 14.5158 10.9267 14.9092C10.0144 15.3025 9.03972 15.4994 8.0025 15.5Z"
        fill="#F43258"
      />
    </svg>
  </span>
);
