import React from 'react';

export const SvgIconVisible = () => (
  <span>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.2353 16.9411V22.2352C22.2353 22.7033 22.0494 23.1521 21.7184 23.4831C21.3875 23.814 20.9386 23.9999 20.4706 23.9999H10.7647C10.2967 23.9999 9.84782 23.814 9.51687 23.4831C9.18592 23.1521 9 22.7033 9 22.2352V12.5294C9 12.0613 9.18592 11.6125 9.51687 11.2815C9.84782 10.9506 10.2967 10.7646 10.7647 10.7646H16.0588"
        stroke="#514B4D"
        strokeWidth="1.41176"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8237 9H23.1178V14.2941"
        stroke="#514B4D"
        strokeWidth="1.41176"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4116 18.7059L23.1175 9"
        stroke="#514B4D"
        strokeWidth="1.41176"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default SvgIconVisible;
