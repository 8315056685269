import { Button, ButtonProps } from 'antd';
import './index.scss';
import SvgIconDetail from '../@svg/SvgIconDetail';
import React from 'react';

interface IButtonDetailProps {
  content: React.ReactNode;
  buttonProps?: ButtonProps;
}

const ButtonDetail = (props: IButtonDetailProps) => {
  const { content, buttonProps } = props;

  return (
    <Button
      {...buttonProps}
      className={`button-detail__container ${buttonProps.className ?? ''}`}
      // icon={<SvgIconDetail />}
      // iconPosition={'end'}
    >
      <span className="button-detail__content">{content}</span>
    </Button>
  );
};

export default ButtonDetail;
