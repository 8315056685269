import React from 'react';

export const SvgDolarIcon = () => (
  <span>
    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.824 14.016C3.432 14.016 1.464 13.472 0.376 12.4L1.432 10.8C2.424 11.68 3.64 12.096 4.824 12.096C6.44 12.096 7.176 11.6 7.176 10.672C7.176 8.576 0.856 9.232 0.856 5.424C0.856 3.344 2.52 2 5.032 2C6.952 2 8.216 2.72 9.304 3.696L8.12 5.104C7.192 4.336 6.216 3.92 5.128 3.92C3.928 3.92 3.112 4.352 3.112 5.264C3.112 7.248 9.432 6.576 9.432 10.464C9.432 12.56 7.768 14.016 4.824 14.016ZM4.312 2.784V0.0159998H5.88V2.784H4.312ZM4.312 16.016V13.072H5.88V16.016H4.312Z"
        fill="#514B4D"
      />
    </svg>
  </span>
);
