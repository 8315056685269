export const SendMessageIcon = () => {
  return (
    <span className="cursor-pointer">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#1C86ED" />
        <path d="M28 12L17 23" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M28 12L21 32L17 23L8 19L28 12Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const UnSendMessageIcon = () => {
  return (
    <span>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#DCDCDC" />
        <path d="M28 12L17 23" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M28 12L21 32L17 23L8 19L28 12Z"
          stroke="#7E7E7E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
