export const UnreadIcon = () => {
  return (
    <span>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.2" cx="10" cy="10" r="10" fill="#1C86ED" />
          <circle cx="10" cy="10" r="7.5" fill="#1C86ED" />
        </svg>
      </svg>
    </span>
  );
};
