import { Input, InputProps } from 'antd';
import { useIntl } from 'react-intl';
import SvgIconSearch from '../@svg/SvgSearch';
import './index.scss';
import SvgIconClose from '../@svg/SvgClose';

const CustomSearchInput = (props: InputProps) => {
  const { ...restProps } = props;
  const intl = useIntl();

  return (
    <Input
      prefix={<SvgIconSearch />}
      allowClear={{ clearIcon: <SvgIconClose /> }}
      {...restProps}
      placeholder={restProps?.placeholder ? restProps.placeholder : ''}
      className={`ant-custom-search-input ${restProps.className}`}
    />
  );
};

export default CustomSearchInput;
