import antdEN from 'antd/locale/en_US';
import common from './common_en.json';
import signin from './signin.json';
import notify from './notify_en.json';
import profile from './profile.json';
import payment from './payment-setting.json';
import rack from './rack.json';
import warehouse from './warehouse.json';
import itemManagement from './itemManagement.json';
import menu from './menu.json';
import error from './error.json';
import validate from './validate.json';
import detailItem from './detailItem.json';
import chat from './chat-support.json';
import requestmanagement from './request_management.json';
import chatItem from './chat-item.json';
import viewStatus from './viewStatus.json';
import requestShipping from './requestShipping.json';
import shipment from './shipment.json';
import storage from './storage.json';
import customerInformation from './customerInformation.json';

const EnLang = {
  messages: {
    ...common,
    ...signin,
    ...notify,
    ...profile,
    ...payment,
    ...rack,
    ...warehouse,
    ...itemManagement,
    ...menu,
    ...error,
    ...validate,
    ...requestmanagement,
    ...chat,
    ...chatItem,
    ...detailItem,
    ...viewStatus,
    ...requestShipping,
    ...shipment,
    ...storage,
    ...customerInformation,
  },
  antd: antdEN,
  locale: 'en-US',
};
export default EnLang;
