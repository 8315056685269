import React from 'react';

export const SvgBlueCircle = () => (
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="12" cy="12" r="10" fill="#1C86ED" />
      <circle cx="12" cy="12" r="7.5" fill="#1C86ED" />
    </svg>
  </span>
);

export default SvgBlueCircle;
