import './index.scss';
import { Checkbox, CheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';

type Props = {
  checkboxProps?: CheckboxProps;
  content?: React.ReactNode | string;
  checked?: boolean;
  onChange?: (checked: boolean, e: CheckboxChangeEvent) => void;
};

const CheckboxCustom: React.FC<Props> = ({ checkboxProps, content, checked, onChange }) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    if (onChange) {
      onChange(e.target.checked, e);
    }
  };

  return (
    <div className="checkbox-custom">
      <Checkbox {...checkboxProps} checked={checked} onChange={handleChange}>
        <span className={`checkbox-custom__checkbox-title`}>{content ?? ''}</span>
      </Checkbox>
    </div>
  );
};

export default CheckboxCustom;
