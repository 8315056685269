import { useMutation, useQuery } from '@tanstack/react-query';
import { Avatar, Button, Empty, Form, Input, Modal, Pagination } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { storageItemApi, storageProcessApi } from '../../../apis';
import SvgCoppy from '../../../components/@svg/SvgCheck';
import SvgIconVisible from '../../../components/@svg/SvgVisible';
import CustomSearchInput from '../../../components/input/CustomSearchInput';
import NotificationError from '../../../components/notifications/NotificationError';
import NotificationSuccess from '../../../components/notifications/NotificationSuccess';
import { RootState } from '../../../store';
import { QUERY_KEY, STATUS_ITEM_MANAGEMENT } from '../../../util/constants';
import {
  formatDate,
  handleShowInquiryDiscussStatus,
  handleShowInternalDiscussStatus,
  Helper,
  renderStatus,
  renderStatusImage,
} from '../../../util/helper';
import './index.scss';
// import CheckboxCustom from '../../../components/buttonCustom/CheckboxCustom';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import { ItemInquiryMessages, ItemInternalMessages, UpdatePreparingDoneDto } from '../../../apis/client-axios';
import { SvgAvatarDefault } from '../../../components/@svg/SvgAvatarDefault';
import SvgBlueCircle from '../../../components/@svg/SvgBlueCircle';
import SvgBoxDrop from '../../../components/@svg/SvgBoxDrop';
import SvgCloseModalIcon from '../../../components/@svg/SvgCloseModalIcon';
import { SvgPrintIcon } from '../../../components/@svg/SvgPrintIcon';
import SvgIncrease from '../../../components/@svg/SvgScanIcon';
import { CustomCommonButton } from '../../../components/buttons/common/CustomButton';
import DatepickerCustom from '../../../components/DatepickerCustom';
import FormWrap from '../../../components/FormWrap';

import { debounce } from 'lodash';
import { NavLink, useNavigate } from 'react-router-dom';
import { SvgMatch } from '../../../components/@svg/SvgMatch';
import { SvgNotMatch } from '../../../components/@svg/SvgNotMatch';
import CheckboxCustom from '../../../components/buttonCustom/CheckboxCustom';
import { ADMIN_ROUTE_PATH } from '../../../constants/router';
import ButtonDetail from '../../../components/buttons/ButtonDetail';

interface IItemDetail {
  sku: string;
  internalSku: string;
  productName: string;
  shipToBuyerItemId: number;
  labelUrl: string | undefined;
}

interface ICheckSku {
  empty: boolean;
  isMatch: boolean;
}

interface ITabAction {
  activeTab: number;
  fullTextSearch: string | undefined;
  checkboxValue: boolean;
  formattedStartDate: string | undefined;
  formattedEndDate: string | undefined;
  tab:
    | 'STOCK'
    | 'SHIP'
    | 'PHOTO_INIT'
    | 'PHOTO_REQUEST'
    | 'DISPOSAL'
    | 'QUESTION'
    | 'CHAT'
    | 'ALL'
    | 'WAITING_STOCK'
    | 'IN_STOCK'
    | 'ITEM_SHIP'
    | 'ITEM_DISPOSAL'
    | 'COMPLETE';
}

const n = (key: keyof UpdatePreparingDoneDto) => key;

const RequestManagement: React.FC = () => {
  const { authUser } = useSelector((state: RootState) => state.auth);
  const [form] = Form.useForm<FormInstance>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [lstData, setLstData] = useState([]);
  const [internalSkuInput, setInternalSkuInput] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [enableApi, setEnableApi] = useState<boolean>(false);
  const [isCheckSku, setIsCheckSku] = useState<ICheckSku>({
    empty: true,
    isMatch: false,
  });
  const navigate = useNavigate();
  const inputRef = useRef<any>(null);
  const [detailItem, setDetailItem] = useState<IItemDetail>({
    sku: '',
    internalSku: '',
    productName: '',
    shipToBuyerItemId: 0,
    labelUrl: '',
  });
  const intl = useIntl();
  const role = authUser?.role || '';
  const { data: countRequest, refetch: refechCount } = useQuery({
    queryKey: [QUERY_KEY.COUNT_TAB_REQUEST],
    staleTime: 1000,
    queryFn: () => storageItemApi.storageItemControllerCountForRequestList(),
  });
  const [fakeSearchValue, setFakeSearchValue] = useState<string>('');

  const tabTitles = [
    {
      index: 0,
      name: intl.formatMessage({ id: 'request.inspection' }),
      text_scheduling: intl.formatMessage({ id: 'request.arrival_date' }),
      quantity: countRequest?.data?.stock,
      role: ['operation'],
      tab: 'STOCK',
    },
    {
      index: 1,
      name: intl.formatMessage({ id: 'request.warehousing_processing' }),
      text_scheduling: intl.formatMessage({ id: 'request.arrival_date' }),
      quantity: countRequest?.data?.stock,
      role: ['administrator'],
      tab: 'STOCK',
    },
    {
      index: 2,
      name: intl.formatMessage({ id: 'request.shipping' }),
      text_scheduling: intl.formatMessage({ id: 'request.request_ship' }),
      text_checkBox: intl.formatMessage({ id: 'request.new_shipping_requests' }),
      quantity: countRequest?.data?.ship,
      role: ['administrator', 'operation'],
      tab: 'SHIP',
    },
    {
      index: 3,
      name: intl.formatMessage({ id: 'request.additional_photo_shoot' }),
      text_scheduling: intl.formatMessage({ id: 'request.date_photo_shot_rq' }),
      text_checkBox: intl.formatMessage({ id: 'request.show_resolved' }),
      quantity: countRequest?.data?.photoRequest,
      role: ['administrator', 'operation'],
      tab: 'PHOTO_REQUEST',
    },
    {
      index: 4,
      name: intl.formatMessage({ id: 'request.image_video_upload' }),
      text_scheduling: intl.formatMessage({ id: 'request.arrival_date' }),
      // text_checkBox: '完了済を表示',
      quantity: countRequest?.data?.photoInit,
      role: ['staff'],
      tab: 'PHOTO_INIT',
    },
    {
      index: 5,
      name: intl.formatMessage({ id: 'request.upload_additional_photos' }),
      text_scheduling: intl.formatMessage({ id: 'request.date_photo_shot_rq' }),
      text_checkBox: intl.formatMessage({ id: 'request.show_resolved' }),
      quantity: countRequest?.data?.photoRequest,
      role: ['staff'],
      tab: 'PHOTO_REQUEST',
    },
    {
      index: 6,
      name: intl.formatMessage({ id: 'request.discard' }),
      text_scheduling: intl.formatMessage({ id: 'request.disposal_request_date' }),
      text_checkBox: intl.formatMessage({ id: 'request.show_resolved' }),
      quantity: countRequest?.data?.disposal,
      role: ['staff'],
      tab: 'DISPOSAL',
    },
    {
      index: 6,
      name: intl.formatMessage({ id: 'request.discard' }),
      text_scheduling: intl.formatMessage({ id: 'request.disposal_request_date' }),
      text_checkBox: intl.formatMessage({ id: 'request.show_completed' }),
      quantity: countRequest?.data?.disposal,
      role: ['administrator', 'operation'],
      tab: 'DISPOSAL',
    },
    {
      index: 7,
      name: intl.formatMessage({ id: 'request.inquiry' }),
      text_scheduling: intl.formatMessage({ id: 'request.mesage_sent_date' }),
      text_checkBox: intl.formatMessage({ id: 'request.show_completed_responses' }),
      quantity: countRequest?.data?.question,
      role: ['administrator', 'staff'],
      tab: 'QUESTION',
    },
    {
      index: 8,
      name: intl.formatMessage({ id: 'request.internal_message' }),
      text_scheduling: intl.formatMessage({ id: 'request.mesage_sent_date' }),
      text_checkBox: intl.formatMessage({ id: 'request.show_completed_responses' }),
      quantity: countRequest?.data?.chat,
      role: ['administrator', 'operation', 'staff'],
      tab: 'CHAT',
    },
  ];

  const [tabAction, setTabAction] = useState<ITabAction>({
    activeTab: 0,
    checkboxValue: false,
    fullTextSearch: undefined,
    formattedStartDate: undefined,
    formattedEndDate: undefined,
    tab: tabTitles[0].tab as any,
  });
  const { data: storageItem, refetch: refetchStorageItem } = useQuery({
    queryKey: [QUERY_KEY.STORAGE_ITEM, tabAction, pageSize, currentPage],
    enabled: enableApi,
    queryFn: async () => {
      if (role === 'administrator') {
        return await storageItemApi.storageItemControllerStorageManagementAdmin(
          currentPage,
          pageSize,
          undefined,
          tabAction.fullTextSearch,
          tabAction.formattedStartDate,
          tabAction.formattedEndDate,
          undefined,
          tabAction.tab,
          tabAction.checkboxValue,
        );
      } else if (role === 'staff') {
        return await storageItemApi.storageItemControllerStorageManagementStaff(
          currentPage,
          pageSize,
          undefined,
          tabAction.fullTextSearch,
          tabAction.formattedStartDate,
          tabAction.formattedEndDate,
          undefined,
          tabAction.tab,
          tabAction.checkboxValue,
        );
      } else if (role === 'operation') {
        return await storageItemApi.storageItemControllerStorageManagementOperation(
          currentPage,
          pageSize,
          undefined,
          tabAction.fullTextSearch,
          tabAction.formattedStartDate,
          tabAction.formattedEndDate,
          undefined,
          tabAction.tab,
          tabAction.checkboxValue,
        );
      }
      return null;
    },
    staleTime: 1000,
  });

  const handleTabChange = (index) => {
    setLstData([]);
    setCurrentPage(1);
    setFakeSearchValue(undefined);
    refechCount();
    setTabAction({
      fullTextSearch: undefined,
      formattedStartDate: undefined,
      formattedEndDate: undefined,
      tab: filteredTabTitles.find((t) => t.index == index)?.tab as any,
      activeTab: filteredTabTitles.findIndex((t) => t.index == index),
      checkboxValue: false,
    });
  };

  useEffect(() => {
    setLstData(storageItem?.data?.content ?? []);
  }, [storageItem?.data?.content]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleShowSizeChange = (current, size) => {
    setCurrentPage(current);
    setPageSize(size);
  };

  const handleCopy = (text, textSuccess, textFailure) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationSuccess({ content: intl.formatMessage({ id: textSuccess }) });
      })
      .catch((err) => {
        NotificationError({ content: intl.formatMessage({ id: textFailure }) });
      });
  };
  const resetState = () => {
    setInternalSkuInput('');
    setIsCheckSku({
      empty: true,
      isMatch: false,
    });
    form.resetFields();
  };
  const filteredTabTitles = tabTitles?.filter((tab) => tab.role.some((r) => r === role));
  useEffect(() => {
    if (authUser && authUser.id) {
      console.log({ filteredTabTitles });
      setEnableApi(true);
      setTabAction({
        ...tabAction,
        tab: filteredTabTitles[0].tab as any,
      });
    }
  }, [authUser]);
  const renderTableContent = () => {
    return (
      <div className="request-management_table_1">
        <div className="request-management_table_1_wrap ebay__scroll-bar-custom">
          <table>
            <tr>
              {[2].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                <th className="request-management_table_1_wrap_col_9"></th>
              )}
              <th className="request-management_table_1_wrap_col_10"></th>
              {[7, 8].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                <th className="request-management_table_1_wrap_col_11"></th>
              )}
              <th className="request-management_table_1_wrap_col_1">{intl.formatMessage({ id: 'request.SKU' })}</th>
              <th className="request-management_table_1_wrap_col_2">
                {intl.formatMessage({ id: 'request.customer' })}
              </th>
              <th className="request-management_table_1_wrap_col_3">
                {intl.formatMessage({ id: 'request.product_name' })}
              </th>
              <th className="request-management_table_1_wrap_col_4">
                {intl.formatMessage({ id: 'request.situation' })}
              </th>
              {[8].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                <th className="request-management_table_1_wrap_col_5">
                  {intl.formatMessage({ id: 'request.manager' })}
                </th>
              )}
              {[8].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                <th className="request-management_table_1_wrap_col_6">
                  {intl.formatMessage({ id: 'request.date_send_new' })}
                </th>
              )}
              {/* <th className='request-management_table_1_wrap_col_7'>
              <th className='request-management_table_1_wrap_col_7'>{}</th> */}
              {[0, 1, 4].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                <th className="request-management_table_1_wrap_col_7_1">
                  {intl.formatMessage({ id: 'request.arrival_date' })}
                </th>
              ) : [3, 5].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                <th className="request-management_table_1_wrap_col_7_2">
                  {intl.formatMessage({ id: 'request.date_photo_shot_rq' })}
                </th>
              ) : [2].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                <th className="request-management_table_1_wrap_col_7_3">
                  {intl.formatMessage({ id: 'request.request_ship' })}
                </th>
              ) : [6].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                <th className="request-management_table_1_wrap_col_7_4">
                  {intl.formatMessage({ id: 'request.disposal_request_date' })}
                </th>
              ) : [7].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                <th className="request-management_table_1_wrap_col_7_5">
                  {intl.formatMessage({ id: 'request.date_send_new' })}
                </th>
              ) : null}
              {/* </th> */}

              {[0, 1, 2, 3, 4, 5, 6].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                <th className="request-management_table_1_wrap_col_8">
                  {intl.formatMessage({ id: 'request.remarks' })}
                </th>
              ) : null}
              {/* {[1, 2].includes(filteredTabTitles[activeTab]?.index) ? <th></th> : null} */}
            </tr>
            {lstData?.map((item) => (
              <tr key={item?.id} className="request-management__table-row-data">
                {[2].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                  <td className="request-management_table_1_wrap_col_9 text-center">
                    {renderStatus(item) === STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING ? (
                      <Button
                        onClick={() => {
                          setIsOpen(true);
                          setDetailItem({
                            sku: item.itemSku,
                            internalSku: item.internalSku,
                            productName: item.itemName,
                            shipToBuyerItemId: item.shipToBuyerItem.id,
                            labelUrl: item.shipToBuyerItem.shipToBuyerRequest.shippoShippingLabel.labelUrl ?? undefined,
                          });
                          resetState();
                        }}
                        className="request-management__popup-shippng"
                      >
                        <SvgBoxDrop />
                      </Button>
                    ) : null}
                  </td>
                ) : null}
                {/* button detail */}
                <td className="request-management_table_1_wrap_col_10 text-center">
                  <ButtonDetail
                    content={intl.formatMessage({ id: 'item.detail' }) + ' '}
                    buttonProps={{
                      onClick: () => {
                        navigate(`${ADMIN_ROUTE_PATH.ITEM_MANAGEMENT_DETAIL}/${item?.id}`);
                      },
                    }}
                  />
                </td>
                {[7, 8].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                  <td className="request-management_table_1_wrap_col_11">
                    <div className="width-24 height-22">
                      {/*logic check show noti blue*/}
                      {[8].includes(filteredTabTitles[tabAction.activeTab]?.index) &&
                        item?.itemMessageGroup?.itemInternalMessages &&
                        !(item?.itemMessageGroup?.itemInternalMessages as unknown as ItemInternalMessages)
                          .internalMessageReadHistories?.id && <SvgBlueCircle />}
                      {[7].includes(filteredTabTitles[tabAction.activeTab]?.index) &&
                        item?.itemMessageGroup?.itemInquiryMessages &&
                        (item?.itemMessageGroup?.itemInquiryMessages as unknown as ItemInquiryMessages)?.sendUserId &&
                        (item?.itemMessageGroup?.itemInquiryMessages as unknown as ItemInquiryMessages).isRead ===
                          0 && <SvgBlueCircle />}
                    </div>
                  </td>
                ) : null}
                <td className="request-management_table_1_wrap_col_1">
                  <div className="request-management__col-copy">
                    <Button
                      className="request-management__btn-copy"
                      onClick={() =>
                        handleCopy(
                          item.internalSku,
                          intl.formatMessage({ id: 'common.copy' }),
                          intl.formatMessage({ id: 'common.dont_copy' }),
                        )
                      }
                    >
                      <SvgCoppy />
                    </Button>
                    <p>{item.internalSku}</p>
                  </div>
                </td>
                <td className="request-management_table_1_wrap_col_2">
                  <div>
                    <p className="text-1-line">{item.storageRequest?.user?.name}</p>
                    <span>{item.storageRequest?.user?.userCode}</span>
                  </div>
                </td>
                <td className="request-management_table_1_wrap_col_3">
                  <div>
                    <p className="text-2-line break-word">{item.itemName}</p>
                  </div>
                </td>
                <td className="request-management_table_1_wrap_col_4">
                  {[0, 1, 2, 4, 6].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                    <p>{intl.formatMessage({ id: `request.${renderStatus(item)}` })}</p>
                  )}
                  {[3].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                    <p>{intl.formatMessage({ id: `request.${renderStatusImage(item?.takePhotoRequest?.status)}` })}</p>
                  )}
                  {[7].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                    <p>
                      {handleShowInquiryDiscussStatus({
                        status:
                          filteredTabTitles[tabAction.activeTab]?.index == 8
                            ? item?.internalDiscussStatus
                            : item?.inquiryStatus,
                        isRead:
                          filteredTabTitles[tabAction.activeTab]?.index == 8
                            ? ((item?.itemMessageGroup?.itemInternalMessages as unknown as ItemInternalMessages)
                                ?.internalMessageReadHistories?.id as number)
                              ? 1
                              : 0
                            : item?.itemMessageGroup?.itemInquiryMessages &&
                                (item?.itemMessageGroup?.itemInquiryMessages as unknown as ItemInquiryMessages)
                                  ?.sendUserId
                              ? (item?.itemMessageGroup?.itemInquiryMessages as unknown as ItemInquiryMessages).isRead
                              : 1,
                        intlMultiLang: intl,
                      })}
                    </p>
                  )}
                  {[8].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                    <p>
                      {handleShowInternalDiscussStatus({
                        status:
                          filteredTabTitles[tabAction.activeTab]?.index == 8
                            ? item?.internalDiscussStatus
                            : item?.inquiryStatus,
                        isRead:
                          filteredTabTitles[tabAction.activeTab]?.index == 8
                            ? ((item?.itemMessageGroup?.itemInternalMessages as unknown as ItemInternalMessages)
                                ?.internalMessageReadHistories?.id as number)
                              ? 1
                              : 0
                            : item?.itemMessageGroup?.itemInquiryMessages &&
                                (item?.itemMessageGroup?.itemInquiryMessages as unknown as ItemInquiryMessages)
                                  ?.sendUserId
                              ? (item?.itemMessageGroup?.itemInquiryMessages as unknown as ItemInquiryMessages).isRead
                              : 1,
                        authUserId: authUser?.id,
                        sendBy: (item?.itemMessageGroup?.itemInternalMessages as unknown as ItemInternalMessages)
                          ?.sendStaffId,
                        intlMultiLang: intl,
                      })}
                    </p>
                  )}
                  {[5].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                    <p>{intl.formatMessage({ id: `request.${renderStatus(item)}` })}</p>
                  )}
                </td>
                {[8].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                  <td className="request-management_table_1_wrap_col_5">
                    <p>
                      <Avatar
                        className=""
                        src={
                          item?.itemMessageGroup?.assign?.avatar?.preview ? (
                            Helper.getSourceFile(item?.itemMessageGroup.assign?.avatar?.preview)
                          ) : (
                            <SvgAvatarDefault />
                          )
                        }
                      />
                    </p>
                  </td>
                )}
                {[8].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                  <td className="request-management_table_1_wrap_col_6">
                    <p>
                      {formatDate(
                        (item?.itemMessageGroup?.itemInternalMessages as unknown as ItemInquiryMessages)?.createdAt,
                      )}
                    </p>
                  </td>
                )}

                {[0, 1, 4].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                  <td className="request-management_table_1_wrap_col_7_1">
                    <div>
                      <p>{formatDate(item.storageRequest?.deliveredAt) || ''}</p>
                    </div>
                  </td>
                )}
                {[5, 3].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                  <td className="request-management_table_1_wrap_col_7_2">
                    <div>
                      <p>{formatDate(item.takePhotoRequest?.createdAt)}</p>
                    </div>
                  </td>
                )}
                {[2].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                  <td className="request-management_table_1_wrap_col_7_3">
                    <div>
                      <p>{formatDate(item.shipToBuyerItem?.shipToBuyerRequest?.createdAt)}</p>
                    </div>
                  </td>
                )}
                {[6].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                  <td className="request-management_table_1_wrap_col_7_4">
                    <div>
                      <p>{formatDate(item.disposalRequest?.createdAt)}</p>
                    </div>
                  </td>
                )}

                {[7].includes(filteredTabTitles[tabAction.activeTab]?.index) && (
                  <td className="request-management_table_1_wrap_col_7_5">
                    <div>
                      <p>
                        {formatDate(
                          (item?.itemMessageGroup?.itemInquiryMessages as unknown as ItemInquiryMessages)?.createdAt,
                        )}
                      </p>
                    </div>
                  </td>
                )}

                {[0, 1, 2, 3, 4, 5, 6].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
                  <td className="request-management_table_1_wrap_col_8">
                    <div>
                      <p className="text-2-line break-word text-pre-line">{item?.storageItemNote?.content}</p>
                    </div>
                  </td>
                ) : null}
              </tr>
            ))}
          </table>
          {!lstData.length && (
            <div className="request-management__table-empty">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
        <div className="request-management__table-pagination-container">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={storageItem?.data?.total}
            showSizeChanger
            pageSizeOptions={[20, 50, 100]}
            locale={{ items_per_page: intl.formatMessage({ id: 'item.lines' }) }}
            onChange={handlePageChange}
            onShowSizeChange={handleShowSizeChange}
          />
        </div>
      </div>
    );
  };
  const handleCheckboxChange = (e) => {
    setLstData([]);
    setTabAction({
      ...tabAction,
      checkboxValue: e,
    });
  };
  const onFinish = (values: UpdatePreparingDoneDto) => {
    if (values) {
      processPreparingDone.mutate({ shipToBuyerItemId: detailItem.shipToBuyerItemId, internalSku: values.internalSku });
    }
  };

  const onFinishFailed = (values) => {
    NotificationError({
      content: intl.formatMessage({ id: 'request.bar_code_error' }),
    });
  };

  const processPreparingDone = useMutation({
    mutationFn: (payload: UpdatePreparingDoneDto) =>
      storageProcessApi.storageProcessControllerSetShipToBuyPreparingDone(payload),
    onSuccess: () => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      refetchStorageItem();
      setIsCheckSku({
        empty: true,
        isMatch: false,
      });
      setIsOpen(false);
    },
    onError: (error: any) => {
      // NotificationError({ content: 'Bad request' });
      // CustomHandleError(error?.response?.data, intl);
    },
  });

  const checkProcessPreparingDone = useMutation({
    mutationFn: (payload: UpdatePreparingDoneDto) =>
      storageProcessApi.storageProcessControllerCheckSetPreparingDone(payload),
    onSuccess: (res: any) => {
      // NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      if (res.data.result) {
        setIsCheckSku({
          empty: false,
          isMatch: true,
        });
      } else {
        setIsCheckSku({
          empty: false,
          isMatch: false,
        });
      }
    },
    onError: (error: any) => {},
  });
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      checkProcessPreparingDone.mutate({
        shipToBuyerItemId: detailItem.shipToBuyerItemId,
        internalSku: internalSkuInput,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, [isOpen]);

  // const handleKeyDownSearch = (event) => {
  //   if (event.key === 'Enter') {
  //     setTimeout(() => {
  //       setTabAction({ ...tabAction, fullTextSearch: event.target.value || undefined });
  //     }, 500);
  //   }
  // };

  const handleSearch = debounce((value: string) => {
    if (!!value?.trim()?.toString()) {
      setTabAction({ ...tabAction, fullTextSearch: value?.trim() });
      setCurrentPage(1);
    }
  }, 500);

  const disabledDateEnd = (current) => {
    if (!!tabAction.formattedStartDate) {
      return current && current < dayjs(tabAction.formattedStartDate).startOf('day');
    } else {
      return undefined;
    }
  };
  const disabledDateStart = (current) => {
    if (!!tabAction.formattedEndDate) {
      return current && current > dayjs(tabAction.formattedEndDate).startOf('day');
    } else {
      return undefined;
    }
  };

  return (
    <div className="request-management">
      <div className="request-management_container">
        <h2 className="request-management_title">{intl.formatMessage({ id: 'request.title' })}</h2>
        <div className="request-management_tab-title">
          {filteredTabTitles.map((tab, index) => (
            <div
              key={index}
              className={`request-management_tab-title_item width_${role} ${
                filteredTabTitles[tabAction.activeTab].index === tab.index ? 'active' : ''
              }`}
              onClick={() => handleTabChange(tab.index)}
            >
              <p className="request-management_tab-title_item_name request-management_tab-title_item-hover">
                {tab.name}
              </p>
              <span className="request-management_tab-title_item_quantity">{tab.quantity}</span>
            </div>
          ))}
        </div>
        <div className="request-management_filter">
          <div className="request-management_filter_date-wrap_date">
            <p className="request-management_filter_date-wrap_date_text m-r-4">
              {filteredTabTitles[tabAction.activeTab]?.text_scheduling}
            </p>
            <DatepickerCustom
              datePickerProp={{
                onChange: (_, dateString: string) => {
                  setTabAction({
                    ...tabAction,
                    formattedStartDate: dateString,
                  });
                },
                disabledDate: disabledDateStart,
                value: tabAction.formattedStartDate ? dayjs(tabAction.formattedStartDate) : undefined,
              }}
            />
            <span>~</span>
            <DatepickerCustom
              datePickerProp={{
                onChange: (_, dateString: string) => {
                  setTabAction({
                    ...tabAction,
                    formattedEndDate: dateString,
                  });
                },
                disabledDate: disabledDateEnd,
                value: tabAction.formattedEndDate ? dayjs(tabAction.formattedEndDate) : undefined,
              }}
            />
          </div>
          {filteredTabTitles[tabAction.activeTab]?.text_checkBox && (
            <div className="request-management_filter_checkbox">
              {/* <div className="checkbox-custom">
                <CustomCheckbox checked={tabAction.checkboxValue} onChange={handleCheckboxChange}>
                  <span className={`checkbox-custom__checkbox-title`}>{filteredTabTitles[tabAction.activeTab].text_checkBox}</span>
                </CustomCheckbox>
              </div> */}
              <CheckboxCustom
                checked={tabAction.checkboxValue}
                onChange={handleCheckboxChange}
                content={filteredTabTitles[tabAction.activeTab].text_checkBox}
              />
            </div>
          )}
          <div>
            <CustomSearchInput
              className="font-weight-500 font-size-16 switch-false request-management__search-input"
              placeholder={intl.formatMessage({ id: 'item.searchPlaceholder' })}
              // onKeyDown={handleKeyDownSearch}
              // value={fakeSearchValue}
              onChange={
                (e) =>
                  e?.target?.value
                    ? handleSearch(e?.target?.value)
                    : setTabAction({ ...tabAction, fullTextSearch: undefined })
                /* {
                setFakeSearchValue(e.target.value);
              } */
              }
            />
          </div>
        </div>
        <div className="request-management_table">{renderTableContent()}</div>
      </div>
      <Modal
        title={intl.formatMessage({ id: 'requestShipping.title' })}
        closable={true}
        open={isOpen}
        centered={true}
        onCancel={() => setIsOpen(false)}
        className={'request-management__modal-shipping'}
        closeIcon={<SvgCloseModalIcon />}
        footer={
          <div className="request-management__btn-container">
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'request-management__btn-print',
                onClick: () => {
                  window.open(detailItem.labelUrl, '_blank');
                },
                disabled: !isCheckSku.isMatch || processPreparingDone.isPending || !detailItem.labelUrl,
              }}
              content={
                <p>
                  <SvgPrintIcon />
                  {intl.formatMessage({ id: 'common.print' })}
                </p>
              }
            />
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                disabled: !isCheckSku.isMatch || processPreparingDone.isPending,
                loading: processPreparingDone.isPending,
                className: 'request-management__btn-cancel',
                type: 'primary',
                onClick: () => form.submit(),
              }}
              content={intl.formatMessage({ id: 'common.ready' })}
            />
          </div>
        }
      >
        <div className="request-management__product-info">
          <p>{intl.formatMessage({ id: 'requestShipping.productInfo' })}</p>
        </div>
        <div className="request-management__gird-container">
          <div className="request-management__row-container">
            <div className="request-management__col-left-container">
              <span>BayPack管理番号</span>
            </div>
            <div className="request-management__col-right-container">
              <span>{detailItem.internalSku}</span>
            </div>
          </div>
          <div className="request-management__row-container">
            <div className="request-management__col-left-container">
              <span>{intl.formatMessage({ id: 'requestShipping.customerSKU' })}</span>
            </div>
            <div className="request-management__col-right-container">
              <span>{detailItem.sku}</span>
            </div>
          </div>
          <div className="request-management__row-container">
            <div className="request-management__col-left-container">
              <span>{intl.formatMessage({ id: 'requestShipping.productName' })}</span>
            </div>
            <div className="request-management__col-right-container">
              <p className="text-1-line">{detailItem.productName}</p>
            </div>
          </div>
        </div>
        <FormWrap
          form={form}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="request-management__form-request-shiping"
        >
          <Form.Item
            name={n('internalSku')}
            className="request-management__product-bar-code m-b-12"
            label={
              <p className="font-size-16">
                {intl.formatMessage({ id: 'requestShipping.productBarcode' })}
                <span className="request-management__require">*</span>
              </p>
            }
            required
            rules={[{ required: true, message: '' }]}
          >
            <Input
              suffix={<SvgIncrease />}
              autoFocus={true}
              className="font-size-16"
              ref={inputRef}
              placeholder={intl.formatMessage({ id: 'request.bar_code_placeholder' })}
              onChange={(e) => {
                const value = e.target.value ?? '';
                setInternalSkuInput(value);
                if (value == '') {
                  resetState();
                }
              }}
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Form.Item className="m-b-12">
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'request-management__btn-confirm font-weight-600',
                onClick: () => {
                  if (internalSkuInput.trim().length > 0) {
                    checkProcessPreparingDone.mutate({
                      shipToBuyerItemId: detailItem.shipToBuyerItemId,
                      internalSku: internalSkuInput.trim(),
                    });
                  } else {
                    form.validateFields();
                  }
                },
              }}
              content={intl.formatMessage({ id: 'request.ship_ready.check' })}
            />
          </Form.Item>
        </FormWrap>
        <div className="request-management__judgement-container">
          <div className="request-management__judgement-title">
            {isCheckSku.empty ? (
              <></>
            ) : (
              <p>
                {intl.formatMessage({ id: 'requestShipping.judgement' })}
                <span>&nbsp;:</span>
              </p>
            )}
          </div>
          <div className="request-management__judgement-description">
            {isCheckSku.empty ? (
              <></>
            ) : (
              <p className="request-management__status-sku">
                {isCheckSku.isMatch ? (
                  <>
                    <span>{intl.formatMessage({ id: 'requestShipping.match' })}</span>
                    <SvgMatch />
                  </>
                ) : (
                  <>
                    {' '}
                    <span>{intl.formatMessage({ id: 'requestShipping.notMatch' })}</span>
                    <SvgNotMatch />
                  </>
                )}
              </p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RequestManagement;
