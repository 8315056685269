import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Avatar, Form, FormInstance, Image, Spin, TabsProps, Upload, UploadProps } from 'antd';
import { createContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { adminApi, assetsApi, itemMessageGroupApi } from '../../../../apis';
import {
  ItemInquiryMessages,
  ItemInternalMessages,
  ItemMessageGroup,
  UpdateItemGroupDto,
} from '../../../../apis/client-axios';
import ButtonItemChatDone from '../../../../components/buttonCustom/ButtonItemChatDone';
import ButtonResponding from '../../../../components/buttonCustom/ButtonRsponding';
import CustomSelect from '../../../../components/CustomSelect';
import FormWrap from '../../../../components/FormWrap';
import CustomInputArea from '../../../../components/input/CustomTextArea';
import CustomConfirmModel from '../../../../components/modals/confirmModel';
import NotificationError from '../../../../components/notifications/NotificationError';
import NotificationSuccess from '../../../../components/notifications/NotificationSuccess';
import CustomeTabs from '../../../../components/tabs';
import { RootState } from '../../../../store';
import { INQUIRY_AND_INTERNAL_STATUS_BE, QUERY_KEY } from '../../../../util/constants';
import { Helper } from '../../../../util/helper';
import { socketConfig } from '../../../../webSocket/webSocket.config';
import { ChannelRegister } from '../../../../webSocket/webSocket.constants';
import { RemoveImageIcon } from '../../chatSupport/@svg/remove';
import { UploadFileSvg } from '../../chatSupport/@svg/upload';
import { SelectAssignSvg } from '../@svg/avatar';
import { AssignChecked } from '../@svg/checked';
import { SendMessageIcon, UnSendMessageIcon } from '../@svg/send';
import { TabIcon } from '../@svg/tab';
import { UnreadIcon } from '../@svg/unread';
import './index.scss';
import { InquiryTab } from './inquiryTab';
import { InternalTab } from './internalTab';

interface UpdateGroup {
  id: number;
  body: UpdateItemGroupDto;
}
export const ChatItemContext = createContext(null);
export const ChatItem = () => {
  const intl = useIntl();
  const { id } = useParams();
  const listRef = useRef(null);
  const [form] = Form.useForm<FormInstance>();
  const [fileList, setFileList] = useState<any>();
  const [fileUploads, setFileUploads] = useState<{ privew: string; source: File }[] | undefined>(undefined);
  const { authUser } = useSelector((state: RootState) => state.auth);
  const [groupSelect, setGroupSelect] = useState<ItemMessageGroup | undefined>(undefined);
  const [listMessage, setListMesage] = useState<ItemInquiryMessages[] | ItemInternalMessages[] | []>([]);
  const [page, setPage] = useState<number>(1);
  const size = useRef<number>(20);
  const [totalReponse, setTotalReponse] = useState<number>(0);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [skipLoad, setSkipLoad] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [assign, setAssign] = useState<number | null>(null);
  const [assignOption, setAssignOption] = useState<{ value: number; label: string | React.ReactNode }[]>([
    { value: null, label: <span className="color-sub-title font-size-12">選択してください</span> },
  ]);
  const [unread, setUnread] = useState<{ inquiryTab: number; internalTab: number }>({ inquiryTab: 0, internalTab: 0 });
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [scrollToEnd, setScrollToEnd] = useState<boolean>(false);
  const [groupChatStatus, setGroupChatStatus] = useState<{ inquiryTab: string; internalTab: string }>({
    inquiryTab: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE,
    internalTab: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE,
  });

  const { data: countUnread, isLoading: isLoadingCount } = useQuery({
    queryKey: [QUERY_KEY.LIST_GROUP_ITEM_MESSAGE_UNREAD],
    queryFn: () => itemMessageGroupApi.itemMessageGroupControllerStaffUnread(+(id as string)),
    enabled: !!id,
    staleTime: 1000,
  });

  const { data: groupData, isLoading: isLoadingGroup } = useQuery({
    queryKey: [QUERY_KEY.LIST_GROUP_ITEM_MESSAGE, id],
    queryFn: () => itemMessageGroupApi.itemMessageGroupControllerGetAll(+(id as string)),
    enabled: !!id && !isLoadingCount,
    staleTime: 1000,
  });

  const {
    data: listStaff,
    isLoading,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_STAFF],
    queryFn: ({ pageParam }) =>
      adminApi.administratorControllerGetAllEmployee(1, undefined, undefined, undefined, true),
    initialPageParam: 1,
    getNextPageParam: (_, __, page) => page + 1,
  });

  const UploadFile = useMutation({
    mutationFn: (dto: File) => assetsApi.assetControllerUploadFile(dto, 'chat-support').then((res) => res?.data),
    onSuccess: () => {},
    onError: (error) => {},
  });

  const UpdateGroup = useMutation({
    mutationFn: (dto: UpdateGroup) =>
      itemMessageGroupApi.itemMessageGroupControllerUpdate(dto?.id as number, dto?.body),
    onSuccess: () => {},
    onError: (error) => {},
  });

  const handleChangeUploadFile: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    if (!!newFileList?.length) {
      setFileList(newFileList);
      try {
        const listUrl = newFileList?.map((file: any) => {
          return {
            privew: (window.URL ? URL : webkitURL).createObjectURL(file?.originFileObj),
            source: file?.originFileObj,
          };
        });
        setFileUploads(listUrl);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRemoveFile = (file: { privew: string; source: any }) => {
    setFileList(fileList.filter((item) => item?.uid != file?.source?.uid));
    setFileUploads(fileUploads?.filter((item: any) => item !== file));
  };

  const handleScroll = () => {
    if (listRef?.current && listMessage.length > 0 && totalReponse >= size.current) {
      const { scrollTop, scrollHeight } = listRef.current;
      if (scrollTop == 0) {
        setPage((prev) => prev + 1);
        setCurrentScrollPosition(scrollHeight);
        setIsLoadMore(true);
        setSkipLoad(false);
        setScrollToEnd(false);
      }
    }
  };

  const handleSelectTab = (group: ItemMessageGroup) => {
    if (groupSelect?.id && group?.id !== groupSelect?.id) {
      const currentStatus = group?.type === 1 ? groupChatStatus.internalTab : groupChatStatus.inquiryTab;
      const unSeen = group?.type === 1 ? +unread?.internalTab : +unread?.inquiryTab;

      if (group?.type === 1) {
        if (
          currentStatus !== INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED &&
          currentStatus !== INQUIRY_AND_INTERNAL_STATUS_BE.OPEN &&
          unSeen > 0
        ) {
          UpdateGroup.mutateAsync({
            id: group?.id,
            body: {
              status: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE,
            },
          });
        }
      } else {
        if (
          (currentStatus === INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED ||
            currentStatus === INQUIRY_AND_INTERNAL_STATUS_BE.NULL) &&
          unSeen > 0
        ) {
          UpdateGroup.mutateAsync({
            id: group?.id,
            body: {
              status: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE,
            },
          });
        }
      }

      const _unread = group?.type === 1 ? unread?.internalTab : unread?.inquiryTab;
      if (+_unread > 0) {
        socketConfig.emit(
          ChannelRegister.READ_MESSAGE,
          JSON.stringify({
            type: group?.type === 1 ? ChannelRegister.SEND_INTERNAL_MESSAGE : ChannelRegister.SEND_INQUIRY_MESSAGE,
            content: {
              itemMessageGroupId: group?.id,
              userId: authUser?.id,
              sendSide: 0,
            },
          }),
        );

        setUnread((prev) => {
          let data = { ...prev };
          if (group?.type === 1) {
            data = {
              ...data,
              internalTab: 0,
            };
          } else {
            data = {
              ...data,
              inquiryTab: 0,
            };
          }
          return data;
        });
      }

      setGroupSelect(group);
      setPage(1);
      setListMesage([]);
      setTotalReponse(0);
      setSkipLoad(false);
      setCurrentScrollPosition(0);
    }
  };

  const handleUpdateStatus = () => {
    if (groupSelect) {
      const currentStatus = groupSelect?.type === 1 ? groupChatStatus.internalTab : groupChatStatus.inquiryTab;

      const statusUpdate =
        currentStatus === INQUIRY_AND_INTERNAL_STATUS_BE.OPEN
          ? INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED
          : INQUIRY_AND_INTERNAL_STATUS_BE.OPEN;

      UpdateGroup.mutateAsync({
        id: groupSelect?.id,
        body: {
          status: statusUpdate,
        },
      });
      if (isOpenConfirm) setIsOpenConfirm(false);
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
    }
  };

  const handleSendMessage = async () => {
    const sendMessage = form.getFieldValue('message')?.toString()?.trim();

    const channel =
      groupSelect && groupSelect?.type === 1
        ? ChannelRegister.SEND_INTERNAL_MESSAGE
        : ChannelRegister.SEND_INQUIRY_MESSAGE;

    if (!!sendMessage) {
      let listUpload: any = [];
      if (!!fileUploads?.length) {
        const promiseUpload = fileUploads?.map((item) =>
          UploadFile.mutateAsync(item?.source).then((rs: any) => {
            return {
              source: rs?.srouce || rs?.preview,
              assetId: rs?.id,
            };
          }),
        );
        listUpload = await Promise.all([...promiseUpload]);
      }

      if (groupSelect?.type === 1) {
        if (groupChatStatus.internalTab !== INQUIRY_AND_INTERNAL_STATUS_BE.OPEN) {
          UpdateGroup.mutateAsync({
            id: groupSelect?.id,
            body: {
              status: INQUIRY_AND_INTERNAL_STATUS_BE.NEW_MESSAGE,
            },
          });
        }
      }

      socketConfig.emit(
        channel,
        JSON.stringify({
          type: channel,
          content: {
            itemMessageGroupId: groupSelect?.id || groupData?.data[0]?.id,
            sendSide: 1,
            sendStaffId: authUser?.id,
            message: sendMessage,
            isClosedInquiryMessage: 0,
            itemMessageImages: listUpload,
          },
        }),
      );

      setFileList([]);
      setFileUploads([]);
      setIsSend(false);
      setScrollToEnd(true);
      form.resetFields();
    } else {
      form.setFields([
        {
          name: 'message',
          errors: [''],
        },
      ]);
    }
  };

  useEffect(() => {
    if (countUnread?.data) {
      if (+(countUnread?.data[0] as any)?.itemInquiryUnread > 0) {
        socketConfig.emit(
          ChannelRegister.READ_MESSAGE,
          JSON.stringify({
            type: ChannelRegister.SEND_INQUIRY_MESSAGE,
            content: {
              itemMessageGroupId: groupData?.data[0]?.id,
              userId: authUser?.id,
              sendSide: 0,
            },
          }),
        );
      }
      setUnread({
        inquiryTab: 0,
        internalTab: (countUnread?.data[1] as any)?.itemInternalUnread,
      });
    }
  }, [countUnread]);

  useEffect(() => {
    if (listRef?.current && listMessage.length > 0 && isLoadMore) {
      const { scrollHeight } = listRef.current;
      if (!scrollToEnd) {
        document.getElementById('scroll-message')?.scrollTo({ top: scrollHeight - currentScrollPosition });
      }
    }
  }, [listMessage, listRef]);

  useEffect(() => {
    if (groupData?.data) {
      let status = {
        inquiryTab: groupData?.data[0]?.status,
        internalTab: groupData?.data[1]?.status,
      };

      if (!!countUnread && +(countUnread?.data[0] as any)?.itemInquiryUnread) {
        socketConfig.emit(
          ChannelRegister.READ_MESSAGE,
          JSON.stringify({
            type: ChannelRegister.SEND_INQUIRY_MESSAGE,
            content: {
              itemMessageGroupId: groupData?.data[0]?.id,
              // userId: authUser?.id,
              sendSide: 0,
            },
          }),
        );

        if (
          groupData?.data[0]?.status === INQUIRY_AND_INTERNAL_STATUS_BE.NULL ||
          groupData?.data[0]?.status === INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED
        ) {
          UpdateGroup.mutateAsync({
            id: groupData?.data[0]?.id,
            body: {
              status: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE,
            },
          });
          status = { ...status, inquiryTab: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE };
        }
      }

      setGroupChatStatus(status);

      setGroupSelect(groupData?.data[0]);

      if (groupData?.data[1]?.assignId) {
        setAssign(groupData?.data[1]?.assignId);
      }
    }
  }, [groupData]);

  useEffect(() => {
    if (listStaff?.pages) {
      let options: { value: number; label: string | React.ReactNode }[] = [];
      listStaff?.pages?.forEach((pages) => {
        pages?.data?.content?.forEach((staff) => {
          options.push({
            value: staff?.id,
            label: (
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    <Avatar size={16} src={Helper.getSourceFile(staff?.avatar?.source)} />
                  </div>
                  <span className="m-l-4 color-sub-title font-size-12 text-1-line" style={{ maxWidth: '110px' }}>
                    {staff?.name || staff?.loginId}
                  </span>
                </div>
                <AssignChecked />
              </div>
            ),
          });
        });
      });
      setAssignOption((prev) => {
        return [...options];
      });
    }
  }, [listStaff]);

  useEffect(() => {
    socketConfig.on(ChannelRegister.SEND_INQUIRY_MESSAGE, (data) => {
      const parseData = JSON.parse(data);
      if (+parseData?.content?.itemMessageGroup?.storageItemId === +id) {
        setGroupSelect((prev) => {
          if (prev?.id === parseData?.content?.itemMessageGroupId) {
            if (parseData?.content?.sendSide == 0) {
              setGroupChatStatus((status) => {
                const currentStatus = status.inquiryTab;
                if (
                  currentStatus === INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED ||
                  currentStatus === INQUIRY_AND_INTERNAL_STATUS_BE.NULL
                ) {
                  UpdateGroup.mutateAsync({
                    id: prev?.id,
                    body: {
                      status: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE,
                    },
                  });
                  return { ...status, inquiryTab: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE };
                }
                return status;
              });

              if (parseData?.content?.isClosedInquiryMessage != 1) {
                socketConfig.emit(
                  ChannelRegister.READ_MESSAGE,
                  JSON.stringify({
                    type: ChannelRegister.SEND_INQUIRY_MESSAGE,
                    content: {
                      itemMessageGroupId: prev?.id,
                      userId: authUser?.id,
                      sendSide: 0,
                    },
                  }),
                );
              }
            }

            setListMesage((prev) => {
              return [...prev, parseData?.content];
            });
          } else {
            if (parseData?.content?.isClosedInquiryMessage != 1) {
              setUnread((prev) => {
                return {
                  ...prev,
                  inquiryTab: +(prev?.inquiryTab || 0) + 1,
                };
              });
            }
          }
          return prev;
        });
      }
    });

    socketConfig.on(ChannelRegister.SEND_INTERNAL_MESSAGE, (data) => {
      const parseData = JSON.parse(data);
      if (+parseData?.content?.itemMessageGroup?.storageItemId === +id) {
        setGroupSelect((prev) => {
          if (prev?.id === parseData?.content?.itemMessageGroupId) {
            if (parseData?.content?.sendStaffId !== authUser?.id) {
              setGroupChatStatus((status) => {
                const currentStatus = status.internalTab;
                if (currentStatus !== INQUIRY_AND_INTERNAL_STATUS_BE.OPEN) {
                  UpdateGroup.mutateAsync({
                    id: prev?.id,
                    body: {
                      status: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE,
                    },
                  });
                }
                return { ...status, internalTab: INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE };
              });
            }

            setListMesage((prev) => {
              return [...prev, parseData?.content];
            });
          } else {
            if (parseData?.content?.isClosedInquiryMessage != 1) {
              setUnread((prev) => {
                return {
                  ...prev,
                  internalTab: +(prev?.internalTab || 0) + 1,
                };
              });
            }
          }
          return prev;
        });
      }
    });

    socketConfig.on(ChannelRegister.CHANGE_STATUS_ITEM_MESSAGE_GROUP, (data) => {
      const parseData = JSON.parse(data);
      if (+parseData?.content?.itemMessageGroup?.storageItemId === +id) {
        setGroupChatStatus((prev) => {
          let status = { ...prev };
          status =
            parseData?.content?.itemMessageGroup?.type === 1
              ? { ...status, internalTab: parseData?.content?.status }
              : { ...status, inquiryTab: parseData?.content?.status };
          return status;
        });
      }
    });

    socketConfig.on(ChannelRegister.CHANGE_ASSIGN_INTERNAL_GROUP, (data) => {
      const parseData = JSON.parse(data);
      setAssignOption((prev) => {
        setAssign(+parseData?.content?.assignId);
        return prev;
      });
    });

    socketConfig.on(ChannelRegister.ITEM_INQUIRY_READ_MESSAGE, (data) => {
      const parseData = JSON.parse(data);
      setUnread({ ...unread, inquiryTab: 0 });
    });

    return () => {
      socketConfig.off(ChannelRegister.SEND_INQUIRY_MESSAGE);
      socketConfig.off(ChannelRegister.SEND_INTERNAL_MESSAGE);
      socketConfig.off(ChannelRegister.CHANGE_STATUS_ITEM_MESSAGE_GROUP);
      socketConfig.off(ChannelRegister.CHANGE_ASSIGN_INTERNAL_GROUP);
      socketConfig.off(ChannelRegister.ITEM_INQUIRY_READ_MESSAGE);
    };
  }, []);

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: (
        <div className="chat-tab d-flex">
          {groupChatStatus.inquiryTab === INQUIRY_AND_INTERNAL_STATUS_BE.OPEN && <TabIcon />}
          <span className="font-size-14 m-l-8">{intl.formatMessage({ id: 'chat-item.inquiry' })}</span>
          {+unread?.inquiryTab > 0 && (
            <span className="unread-icon">
              <UnreadIcon />
            </span>
          )}
        </div>
      ),
      children: <></>,
    },
    {
      key: '1',
      label: (
        <div className="chat-tab d-flex">
          {groupChatStatus.internalTab === INQUIRY_AND_INTERNAL_STATUS_BE.OPEN && <TabIcon />}
          <span className="font-size-14 m-l-8">{intl.formatMessage({ id: 'chat-item.internal' })}</span>
          {+unread?.internalTab > 0 && (
            <span className="unread-icon">
              <UnreadIcon />
            </span>
          )}
        </div>
      ),
      children: <></>,
    },
  ];

  return (
    <ChatItemContext.Provider
      value={{
        listMessage: listMessage as ItemInternalMessages[] | ItemInquiryMessages[],
        setListMesage: setListMesage,
        page: page,
        setPage: setPage,
        totalReponse: totalReponse,
        setTotalResponse: setTotalReponse,
        size: size.current,
        skipLoad: skipLoad,
        setSkipLoad: setSkipLoad,
        fileUploads: fileUploads,
        setFileUploads: setFileUploads,
        scrollToEnd: scrollToEnd,
        groupSelect: groupSelect,
      }}
    >
      <div id="chat-item">
        <FormWrap form={form}>
          <div className="item-detail_message">
            <div className="item-detail_message_title">
              <Spin spinning={isLoadingGroup || isLoadingCount}>
                <CustomeTabs
                  defaultActiveKey="0"
                  items={items}
                  onChange={(key) => {
                    handleSelectTab(groupData?.data[key] as ItemMessageGroup);
                  }}
                />
              </Spin>
            </div>
            <div className="item-detail_message_status d-flex align-items-center justify-content-between">
              {(groupSelect?.type === 1 ? groupChatStatus.internalTab : groupChatStatus.inquiryTab) ===
              INQUIRY_AND_INTERNAL_STATUS_BE.OPEN ? (
                <ButtonItemChatDone
                  value={intl.formatMessage({ id: 'chat-item.status.open' })}
                  onClick={() => setIsOpenConfirm(true)}
                />
              ) : (
                <ButtonResponding
                  value={intl.formatMessage({ id: 'chat-item.status.closed' })}
                  onClick={handleUpdateStatus}
                />
              )}
              {groupSelect?.type === 1 && (
                <div className="d-flex align-items-center justify-content-between">
                  <SelectAssignSvg />
                  <span className="m-l-4 m-r-8 font-size-10 color-sub-title">
                    {intl.formatMessage({ id: 'chat-item.assign' })}
                  </span>
                  <div className="width-200" style={{ height: '28px' }}>
                    <Form.Item name="assign">
                      <CustomSelect
                        selectProps={{
                          style: { width: '180px ' },
                          placeholder: intl.formatMessage({ id: 'chat-item.assign.placeholder' }),
                          options: assignOption,
                          value: assign,
                          // open: true,
                          onSelect: (value) => {
                            if (!!value) {
                              const message = intl.formatMessage({
                                id: `chat-item.assign.${assign ? 'change' : 'set'}`,
                              });

                              UpdateGroup.mutateAsync({
                                id: groupSelect?.id as number,
                                body: {
                                  assignId: value,
                                },
                              });
                              NotificationSuccess({ content: message });
                            }
                            // setAssign(value);
                          },
                          onClear: () => {
                            UpdateGroup.mutateAsync({
                              id: groupSelect?.id as number,
                              body: {
                                assignId: null,
                              },
                            });
                            setAssign(null);
                            NotificationSuccess({ content: intl.formatMessage({ id: `chat-item.assign.remove` }) });
                          },
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
            <div className="item-detail_message_content" onScroll={handleScroll} ref={listRef} id="scroll-message">
              {groupSelect && groupSelect?.type === 1 ? <InternalTab /> : <InquiryTab />}
            </div>
            <div className="item-detail_message_input-box">
              <div style={{ flex: '1' }}>
                {!!fileUploads && fileUploads?.length > 0 && (
                  <div className="item-detail_message_input-box-uploads">
                    {fileUploads?.map((file) => {
                      return (
                        <div key={file?.privew} className="item-detail_message_input-box-uploads_wrap">
                          <Image preview={false} width={40} height={40} className="rounded" src={file.privew} />
                          <span
                            onClick={() => handleRemoveFile(file)}
                            className="item-detail_message_input-box-uploads_close"
                          >
                            <RemoveImageIcon />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                <Form.Item name="message" className="m-b-0" style={{ flex: 1 }}>
                  <CustomInputArea
                    className="color-sub-title font-size-12"
                    placeholder={intl.formatMessage({ id: 'chat-item.send.placeholder' })}
                    autoFocus={true}
                    onChange={(e) => {
                      if (e?.target?.value?.toString()?.trim()) {
                        setIsSend(true);
                      } else {
                        setIsSend(false);
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="item-detail_message_input-box_wrap">
                <div className="d-flex align-items-center" style={{ gap: '24px' }}>
                  <Form.Item name="file-upload" className="m-b-0">
                    <Upload
                      className="cursor-pointer"
                      multiple={true}
                      maxCount={5}
                      showUploadList={false}
                      onChange={handleChangeUploadFile}
                      beforeUpload={(file, fileList) => {
                        if (file && fileUploads?.length === 5) {
                          NotificationError({ content: 'ファイルのアップロード制限を超えました' });
                        }
                      }}
                      fileList={fileList}
                      accept="image/*"
                    >
                      <UploadFileSvg />
                    </Upload>
                  </Form.Item>
                  <Spin spinning={UploadFile.isPending}>
                    <span>
                      {isSend ? (
                        <span onClick={handleSendMessage}>
                          <SendMessageIcon />
                        </span>
                      ) : (
                        <span>
                          <UnSendMessageIcon />
                        </span>
                      )}
                    </span>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </FormWrap>
        <CustomConfirmModel
          isOpen={isOpenConfirm}
          setIsOpen={() => setIsOpenConfirm(!isOpenConfirm)}
          onFinish={handleUpdateStatus}
          idDataDetail={0}
          titleText={intl.formatMessage({ id: 'chat-item.modal.title' })}
          cancelText={intl.formatMessage({ id: 'common.cancel' })}
          confirmText={intl.formatMessage({ id: 'common.yes' })}
          messageText={
            <span className="font-size-16 color-sub-title">
              {intl.formatMessage({ id: 'chat-item.modal.content' })}
            </span>
          }
        />
      </div>
    </ChatItemContext.Provider>
  );
};
