export enum ChannelRegister {
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
  SYSTEM = 'SYSTEM',
  JOIN_CONVERSATION = 'JOIN_CONVERSATION',
  REOPEN_CONVERSATION = 'REOPEN_CONVERSATION',
  CHAT_SUPPORT_READ_MESSAGE = 'CHAT_SUPPORT_READ_MESSAGE',

  //message
  CREATE_CONVERSATION = 'CREATE_CONVERSATION',
  READ_MESSAGE = 'READ_MESSAGE',
  ITEM_INQUIRY_READ_MESSAGE = 'ITEM_INQUIRY_READ_MESSAGE',

  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_INTERNAL_MESSAGE = 'SEND_INTERNAL_MESSAGE',
  SEND_INQUIRY_MESSAGE = 'SEND_INQUIRY_MESSAGE',
  CHANGE_ASSIGN_INTERNAL_GROUP = 'CHANGE_ASSIGN_INTERNAL_GROUP',
  CHANGE_STATUS_ITEM_MESSAGE_GROUP = 'CHANGE_STATUS_ITEM_MESSAGE_GROUP',
}
