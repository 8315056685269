export const AssignChecked = () => {
  return (
    <span className="assign-icon-checked d-none">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.75 8.75L6.25 12.25L13.25 4.75"
          stroke="#514B4D"
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
