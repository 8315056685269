import BrotherSdk from 'bpac-js';
import NotificationError from '../components/notifications/NotificationError';
export const printSKU = async (sku: string, errorString: string) => {
  const printer = new BrotherSdk({
    templatePath:
      'https://cuonglt.s3.ap-southeast-2.amazonaws.com/ebay-return/SKU%E3%83%A9%E3%83%99%E3%83%AB_20220808_edited.lbx',
    exportDir: 'F:/TCOM/eBayReturn/',
  });

  const data = {
    SKU: sku,
    txtSKU: sku,
  };

  // const options = {
  //   copies: 1, // Optional - Defaults: 1
  //   printName: sku, // Optional - Defaults: BPAC-Document
  //   highResolution: true // Optional
  // }

  try {
    // const isPrinted = await printer.getImageData(data, { width: 2000, height: 4000 });
    const isPrinted = await printer.print(data, { quality: true, highResolution: true, autoCut: true });
    // console.log({ isPrinted });
  } catch (error) {
    console.log({ error });
    NotificationError({
      content: errorString,
    });
  }
};
