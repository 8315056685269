import { Form, Modal, ModalProps } from 'antd';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { DataDetail } from '../../../routes/admin/notify';
import FormWrap from '../../FormWrap';
import { CustomCommonButton } from '../../buttons/common/CustomButton';
import CustomInput from '../../input/CustomInput';
import '../index.scss';
import { ValidateLibrary } from '../../../validate';

export interface IFormData {
  title: string;
  content: string;
}
interface customModalProps extends ModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onFinish: (values: IFormData) => void;
  dataDetail?: DataDetail;
}
const CustomNotificationModel = (props: customModalProps) => {
  const intl = useIntl();
  const [form] = Form.useForm<IFormData>();
  const { isOpen, setIsOpen, onFinish, className, dataDetail, ...restProps } = props;
  useEffect(() => {
    if (dataDetail) {
      form.setFieldsValue(dataDetail);
    } else {
      form.resetFields();
    }
  }, [dataDetail, isOpen]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={
        <span className="color-title font-weight-700 font-size-24">
          {' '}
          {dataDetail ? (!dataDetail?.timeSend ? 'お知らせ編集' : 'お知らせ詳細') : 'お知らせ登録'}{' '}
        </span>
      }
      className={`ant-custom-modal notification-models ${className} m-b-0`}
      open={isOpen}
      onCancel={onClose}
      {...restProps}
      footer={[
        <div className="m-t-60">
          <CustomCommonButton
            isCancelButton={true}
            buttonProps={{
              className: 'bg-sub-cancel color-title button-small border-9D9D9D',
              onClick: () => setIsOpen(false),
              block: true,
            }}
            content={
              <span className="color-sub-title font-size-16 font-weight-600">
                {dataDetail?.timeSend ? '閉じる' : ' キャンセル'}
              </span>
            }
          ></CustomCommonButton>
          {(!dataDetail || !dataDetail?.timeSend) && (
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                type: 'primary',
                className: 'bg-cus-primary text-white m-l-24  button-small',
                onClick: () => form.submit(),
                block: true,
              }}
              content={<span className="font-size-16 font-weight-600">登録</span>}
            ></CustomCommonButton>
          )}
        </div>,
      ]}
    >
      <FormWrap form={form} onFinish={onFinish}>
        <Form.Item
          label={<span className="font-size-16 color-sub-title">{intl.formatMessage({ id: 'notify.title' })}</span>}
          name={'title'}
          required
          rules={ValidateLibrary(intl).required}
          className="m-t-24 m-b-0"
        >
          <CustomInput
            disabled={!!dataDetail?.timeSend}
            placeholder="タイトルを入力"
            className={`color-sub-title font-size-16 ${!!dataDetail?.timeSend ? 'bg-disable' : ''} `}
          />
        </Form.Item>
        <Form.Item
          label={<span className="font-size-16 color-sub-title">{intl.formatMessage({ id: 'notify.content' })}</span>}
          name={'content'}
          required
          rules={ValidateLibrary(intl).required}
          className="m-t-24 m-b-0"
        >
          <CustomInput.TextArea
            disabled={!!dataDetail?.timeSend}
            placeholder="お知らせ内容を入力"
            rows={7}
            className={`color-sub-title font-size-16 ${!!dataDetail?.timeSend ? 'bg-disable' : ''} `}
          />
        </Form.Item>
      </FormWrap>
    </Modal>
  );
};
export default CustomNotificationModel;
