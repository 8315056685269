import { Input, InputProps } from 'antd';
import { useIntl } from 'react-intl';
import './index.scss';
import { TextAreaProps } from 'antd/es/input';

interface CustomInputProps extends TextAreaProps {
  placeholder?: string;
  className?: string;
  isPassword?: boolean;
  width?: number;
  height?: number;
}

const CustomInputArea = (props: CustomInputProps) => {
  const intl = useIntl();
  const { placeholder, className, isPassword, width, height, ...restProps } = props;

  const inputStyle = { height, width };

  return (
    <Input.TextArea
      style={inputStyle}
      placeholder={placeholder || undefined}
      className={`ant-custom-input-area ${className}`}
      {...restProps}
    ></Input.TextArea>
  );
};

export default CustomInputArea;
