import { Form, Modal, ModalProps } from 'antd';
import { FormInstance } from 'antd/lib';
import { useIntl } from 'react-intl';
import { CustomCommonButton } from '../../buttons/common/CustomButton';
import '../index.scss';

interface customModalProps extends ModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen: (value) => void;
  onFinish: (id: number) => void;
  titleText?: string | React.ReactNode;
  cancelText?: string | React.ReactNode;
  confirmText?: string | React.ReactNode;
  messageText?: string | React.ReactNode;
  idDataDetail: number;
}

const CustomConfirmModel = (props: customModalProps) => {
  const intl = useIntl();
  const [form] = Form.useForm<FormInstance>();
  const {
    isOpen,
    setIsOpen,
    onFinish,
    className,
    titleText,
    cancelText,
    confirmText,
    messageText,
    idDataDetail,
    ...restProps
  } = props;

  return (
    <Modal
      title={<span className="color-title font-weight-700 font-size-24">{titleText || 'お知らせ登録'}</span>}
      className={`confirm-models ${className}`}
      open={isOpen}
      {...restProps}
      onCancel={() => setIsOpen(undefined)}
      footer={[
        <div className="m-t-36">
          <CustomCommonButton
            isCancelButton={true}
            buttonProps={{
              className: 'bg-sub-cancel color-title m-r-24 button-small border-9D9D9D',
              onClick: () => setIsOpen(false),
              block: true,
            }}
            content={
              <span className="color-sub-title font-size-16 font-weight-600">
                {cancelText || intl.formatMessage({ id: 'common.cancel' })}
              </span>
            }
          ></CustomCommonButton>
          <CustomCommonButton
            isCancelButton={true}
            buttonProps={{
              type: 'primary',
              className: 'bg-cus-primary text-white m-l-0 button-small',
              onClick: () => onFinish(idDataDetail),
              block: true,
            }}
            content={
              <span className="font-size-16 font-weight-600">
                {confirmText || intl.formatMessage({ id: 'common.confirm' })}
              </span>
            }
          ></CustomCommonButton>
        </div>,
      ]}
    >
      {messageText ? messageText : <div>{messageText || '送信した後、取り消すことができません。'}</div>}
    </Modal>
  );
};

export default CustomConfirmModel;
