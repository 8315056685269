import React from 'react';

export const SvgMatch = () => (
  <span>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 7.33341L9.215 9.37008C9.3832 9.49623 9.59287 9.55414 9.80197 9.53219C10.0111 9.51024 10.2042 9.41007 10.3425 9.25175L15.6667 3.16675"
        stroke="#1C86ED"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M16.5 9.00011C16.5 10.5672 16.0092 12.095 15.0964 13.3688C14.1836 14.6426 12.8947 15.5985 11.4108 16.1022C9.92683 16.606 8.32237 16.6322 6.82275 16.1773C5.32314 15.7223 4.00369 14.8091 3.04973 13.5658C2.09577 12.3225 1.55522 10.8116 1.50401 9.24535C1.45279 7.67908 1.89348 6.13612 2.76419 4.83316C3.63489 3.5302 4.89186 2.53271 6.35855 1.98079C7.82525 1.42887 9.42799 1.35024 10.9417 1.75594"
        stroke="#1C86ED"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  </span>
);
