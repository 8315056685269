import { Pagination, Space, Spin, Tooltip } from 'antd';
import CustomSearchInput from '../../../components/input/CustomSearchInput';
import dayjs from 'dayjs';
import './index.scss';
import TableWrap from '../../../components/TableWrap';
import Column from 'antd/es/table/Column';
import SvgSend from '../../../components/@svg/SvgSend';
import SvgPencil from '../../../components/@svg/SvgPencil';
import SvgIconDelete from '../../../components/@svg/SvgIconDelete';
import CustomButton from '../../../components/buttons/CustomButton';
import { IFormData } from '../../../components/modals/notificationModel';
import { useEffect, useRef, useState } from 'react';
import CustomNotificationModel from '../../../components/modals/notificationModel';
import CustomConfirmModel from '../../../components/modals/confirmModel';
import { systemNotificationApi } from '../../../apis';
import { CreateNotiDto } from '../../../apis/client-axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import SvgIconVisible from '../../../components/@svg/SvgVisible';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { QUERY_KEY, TIME_FORMAT } from '../../../util/constants';
import { debounce } from 'lodash';
import NotificationSuccess from '../../../components/notifications/NotificationSuccess';

export interface DataDetail {
  id: number;
  title: string;
  content: string;
  timeSend?: String;
}

const ListNotifyManagement = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState<{ isOpen: boolean; id: string | number } | undefined>(undefined);
  const [dataDetail, setDataDetail] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [dataSearch, setDataSearch] = useState<string | undefined>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const queryClient = useQueryClient();

  const {
    data: listSystemNotification,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: [QUERY_KEY.LIST_NOTIFICATION, page, pageSize, dataSearch],
    // staleTime: 1000,
    queryFn: () => systemNotificationApi.systemNotificationControllerGet(page, pageSize, undefined, dataSearch),
  });

  const createNoti = useMutation({
    mutationFn: (payload: CreateNotiDto) => systemNotificationApi.systemNotificationControllerCreate(payload),
    onSuccess: () => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.createSuccess' }) });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.LIST_NOTIFICATION],
      });
    },
  });

  const updateNoti = useMutation({
    mutationFn: (payload: CreateNotiDto) =>
      systemNotificationApi.systemNotificationControllerUpdate(dataDetail?.id, payload),
    onSuccess: () => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.LIST_NOTIFICATION],
      });
    },
  });

  const sendNoti = useMutation({
    mutationFn: (id: number) => systemNotificationApi.systemNotificationControllerSend(id),
  });

  const deleteNoti = useMutation({
    mutationFn: (id: number) => systemNotificationApi.systemNotificationControllerDelete(id),
    onSuccess: () => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.deleteSuccess' }) });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.LIST_NOTIFICATION],
      });
      setIsOpenDelete(undefined);
    },
  });

  const onFinish = (value: IFormData) => {
    if (dataDetail?.id) {
      updateNoti.mutate(value);
      // setData((prevData) => prevData.map((item) => (item.id === dataDetail?.id ? { ...item, ...value } : item)));
      setIsOpen(false);
    } else {
      createNoti.mutate(value);
      // setData((prevData) => [value, ...prevData]);
      setIsOpen(false);
    }
    setPage(1);
  };

  const onFinishSend = (id: number) => {
    sendNoti.mutate(id);
    setData((prevData) =>
      prevData.map((item) => (item.id === dataDetail?.id ? { ...item, timeSend: new Date() } : item)),
    );
    setIsOpenConfirm(false);
  };

  const handleSearch = debounce((value: string) => {
    setDataSearch(value?.toString()?.trim());
    setPage(1);
  }, 500);

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter' && dataSearch) {
  //     setPage(1);
  //     refetch();
  //   }
  // };

  const handleCreate = () => {
    setIsOpen(true);
    setDataDetail(null);
  };

  const handleDeleteNoti = () => {
    setPage(1);
    if (isOpenDelete?.id) {
      deleteNoti.mutate(isOpenDelete?.id as number);
    }
  };

  useEffect(() => {
    if (listSystemNotification?.data) {
      // if (!dataSearch) {
      //   setData((prevData) => [...prevData, ...listSystemNotification.data.content]);
      // } else {

      // }

      if (page > 1) {
        setData((prevData) => [...prevData, ...listSystemNotification.data.content]);
      } else {
        setData(listSystemNotification.data.content);
      }
      setTotal(listSystemNotification.data.total);
    }
  }, [listSystemNotification, page]);

  return (
    <div className="list-notify">
      <div className="list-notify-title">{intl.formatMessage({ id: 'notify.notification_management' })}</div>
      <div className="list-notify-search-button m-t-26">
        <div>
          <CustomSearchInput
            className="font-size-16 p-l-8"
            placeholder="検索"
            onChange={(e) => (e?.target?.value ? handleSearch(e?.target?.value) : setDataSearch(undefined))}
            // onKeyDown={handleKeyPress}
          />
        </div>
        <div className="list-notify-search-button-button">
          <CustomButton onClick={handleCreate} key="2" type="text" className="bg-cus-primary text-white m-l-0">
            <span className="font-size-16 font-weight-600">{intl.formatMessage({ id: 'notify.signup' })}</span>
          </CustomButton>
        </div>
      </div>

      <TableWrap
        className="m-t-24"
        data={data ?? []}
        isLoading={isLoading}
        page={page}
        size={pageSize}
        total={total ?? 0}
        rowKey="id"
        setPage={setPage}
        setSize={setPageSize}
        isScroll
        scrollValue={{ y: 550 }}
      >
        <Column
          title={intl.formatMessage({ id: 'notify.no1' })}
          dataIndex="key"
          key="key"
          align="center"
          width={'56px'}
          render={(_: any, record: DataDetail, index: number) => <div>{index + 1}</div>}
        />
        <Column
          title="タイトル"
          dataIndex="title"
          key="title"
          width={'300px'}
          render={(_) => <div className="text-2-line">{_}</div>}
        />
        <Column
          title="内容"
          dataIndex="content"
          key="content"
          render={(_) => <div className="text-2-line">{_}</div>}
          width={'900px'}
        />
        <Column
          title={intl.formatMessage({ id: 'notify.send_date_and_time' })}
          dataIndex="timeSend"
          key="timeSend"
          width={'220px'}
          render={(_: any, record: DataDetail, index: number) => (
            <div className="text-nowrap">{record?.timeSend ? dayjs(_).format(TIME_FORMAT.DATE_TIME) : '未送信'}</div>
          )}
        />
        <Column
          key="action"
          width={'164px'}
          render={(_: any, record: DataDetail) => (
            <div>
              {!record.timeSend ? (
                <Space size="middle">
                  <div
                    onClick={() => {
                      setDataDetail(record);
                      setIsOpen(true);
                    }}
                    className="cursor-pointer"
                  >
                    <SvgPencil />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setIsOpenDelete({ isOpen: true, id: record.id });
                    }}
                  >
                    <SvgIconDelete />
                  </div>
                  <Tooltip
                    placement="bottom"
                    title={<span className="color-sub-title font-size-12">送信</span>}
                    arrow={false}
                  >
                    <div
                      onClick={() => {
                        setDataDetail(record);
                        setIsOpenConfirm(true);
                      }}
                      className="cursor-pointer"
                    >
                      <SvgSend />
                    </div>
                  </Tooltip>
                </Space>
              ) : (
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setDataDetail(record);
                  }}
                  className="cursor-pointer"
                >
                  <SvgIconVisible />
                </div>
              )}
            </div>
          )}
        />
      </TableWrap>

      <CustomNotificationModel isOpen={isOpen} setIsOpen={setIsOpen} onFinish={onFinish} dataDetail={dataDetail} />
      <CustomConfirmModel
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        onFinish={onFinishSend}
        idDataDetail={dataDetail?.id}
        titleText={intl.formatMessage({ id: 'notify.want_send_noti' })}
        cancelText={intl.formatMessage({ id: 'common.cancel' })}
        confirmText={intl.formatMessage({ id: 'common.send' })}
        messageText={intl.formatMessage({ id: 'notify.once_sent_not_cancel' })}
      />

      <CustomConfirmModel
        isOpen={!!isOpenDelete?.isOpen}
        setIsOpen={setIsOpenDelete}
        onFinish={handleDeleteNoti}
        idDataDetail={dataDetail?.id}
        titleText={intl.formatMessage({ id: 'common.confirm-delete' })}
        cancelText={<span className="font-size-16 font-weight-600 color-sub-title">OK 削除</span>}
        confirmText={<span className="font-size-16 font-weight-600 text-white">キャンセル</span>}
        messageText={intl.formatMessage({ id: 'common.confirm-delete-text' })}
      />
    </div>
  );
};

export default ListNotifyManagement;
