import React from 'react';
import './viewStatus.scss';
import { STATUS_ITEM_MANAGEMENT } from '../../util/constants';
import { useIntl } from 'react-intl';

interface IViewStatus {
  status: string;
}

const ViewStatus: React.FC<IViewStatus> = ({ ...props }) => {
  const { status } = props;
  const intl = useIntl();
  // console.log('=====>', { status });
  switch (status) {
    case STATUS_ITEM_MANAGEMENT.NOT_SHIPPED:
      return (
        <div className="view-status__container view-status__not-shipped">
          <span>{intl.formatMessage({ id: 'viewStatus.notShipped' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.IN_TRANSIT:
      return (
        <div className="view-status__container view-status__in-transit">
          <span>{intl.formatMessage({ id: 'viewStatus.inTransit' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.DELIVERED:
      return (
        <div className="view-status__container view-status__delivered">
          <span>{intl.formatMessage({ id: 'viewStatus.delivered' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.WAITING_STORAGE:
      return (
        <div className="view-status__container view-status__waiting-storage">
          <span>{intl.formatMessage({ id: 'viewStatus.waitingStorage' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO:
      return (
        <div className="view-status__container view-status__waiting-upload-image">
          <span>{intl.formatMessage({ id: 'viewStatus.waitingUploadImage' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.IN_STORAGE:
      return (
        <div className="view-status__container view-status__in-storage">
          <span>{intl.formatMessage({ id: 'viewStatus.inStorage' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING:
      return (
        <div className="view-status__container view-status__shipping-preparing">
          <span>{intl.formatMessage({ id: 'viewStatus.shippingPreparing' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING_DONE:
      return (
        <div className="view-status__container view-status__shipping-preparing-done">
          <span>{intl.formatMessage({ id: 'viewStatus.shippingPreparingDone' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.SHIPPED_BACK:
      return (
        <div className="view-status__container view-status__shipped-back">
          <span>{intl.formatMessage({ id: 'viewStatus.shippedBack' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.SHIPPED:
      return (
        <div className="view-status__container view-status__shipped">
          <span>{intl.formatMessage({ id: 'viewStatus.shipped' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.NEW_DISPOSAL_REQUEST:
      return (
        <div className="view-status__container view-status__new-disposal-request">
          <span>{intl.formatMessage({ id: 'viewStatus.newDisposalRequest' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.PRICE_INPUTED:
      return (
        <div className="view-status__container view-status__price-inputed">
          <span>{intl.formatMessage({ id: 'viewStatus.priceInputed' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.DISPOSED:
      return (
        <div className="view-status__container view-status__disposal-done">
          <span>{intl.formatMessage({ id: 'viewStatus.disposalDone' })}</span>
        </div>
      );
    case STATUS_ITEM_MANAGEMENT.CANCELED:
      return (
        <div className="view-status__container view-status__canceled">
          <span>{intl.formatMessage({ id: 'viewStatus.canceled' })}</span>
        </div>
      );
    default:
      return <></>;
  }
};

export default ViewStatus;
