import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, FormInstance, Spin, Upload, UploadProps } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { assetsApi, storageProcessApi } from '../../../../../apis';
import { TakePhotoRequest, UpdateStorageItemPhotos, UpdateTakePhotos } from '../../../../../apis/client-axios';
import ButtonAgree from '../../../../../components/buttonCustom/ButtonAgree';
import ButtonCancel from '../../../../../components/buttonCustom/ButtonCancel';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/notifications/NotificationError';
import NotificationSuccess from '../../../../../components/notifications/NotificationSuccess';
import { QUERY_KEY, TAKE_PHOTO_REQUEST_STATUS } from '../../../../../util/constants';
import { Helper } from '../../../../../util/helper';
import { ValidateLibrary } from '../../../../../validate';
import { LoadingOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export interface TakePhotoInterFace {
  activeAddPhoto: { isShow: boolean; takePhotoRequest: TakePhotoRequest };
  setActiveAddPhoto: React.Dispatch<React.SetStateAction<{ isShow: boolean; takePhotoRequest: TakePhotoRequest }>>;
  handleCloseAddPhoto: () => void;
  renderTextStatusPhotoRequest: (value: string) => React.ReactNode;
}
const TakePhotoModal = (props: TakePhotoInterFace) => {
  const { activeAddPhoto, setActiveAddPhoto, handleCloseAddPhoto, renderTextStatusPhotoRequest } = props;
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [form] = Form.useForm<FormInstance>();
  const [isUpload, setIsUpload] = useState(true);
  const [fileList, setFileList] = useState<any>();
  const [fileUploads, setFileUploads] = useState<
    { id: number | null; preview: string; source: File | null }[] | undefined
  >(undefined);
  const [isShowMessageTranslate, setIsShowMessageTranslate] = useState(false);

  const UploadFile = useMutation({
    mutationFn: (dto: File) => assetsApi.assetControllerUploadFile(dto, 'chat-item').then((res) => res?.data),
    onSuccess: () => {},
    onError: (error) => {},
  });

  const { mutate: translateUserMessage, isPending: isTranslating } = useMutation({
    mutationFn: () =>
      storageProcessApi.storageProcessControllerTranslatePhotoRequest({
        photoRequestId: activeAddPhoto.takePhotoRequest.id,
      }),
    onSuccess: (res) => {
      if (res.data?.length > 0) {
        setActiveAddPhoto({
          ...activeAddPhoto,
          takePhotoRequest: {
            ...activeAddPhoto.takePhotoRequest,
            userMessageTranslated: res.data,
          },
        });
      }
    },
    onError: (error) => {
      setIsShowMessageTranslate(false);
    },
  });

  const UpdateStorageProcessMutation = useMutation({
    mutationFn: (dto: UpdateTakePhotos) => storageProcessApi.storageProcessControllerTakePhotoRequest(dto),
    onSuccess: () => {
      setActiveAddPhoto({
        ...activeAddPhoto,
        isShow: false,
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ITEM_DETAIL],
        stale: true,
      });
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
    },
    onError: (error) => {},
  });

  const UpdatePhotoRequestMutation = useMutation({
    mutationFn: (dto: UpdateStorageItemPhotos) => storageProcessApi.storageProcessControllerUpdatePhotoRequest(dto),
    onSuccess: () => {
      setActiveAddPhoto({
        ...activeAddPhoto,
        isShow: false,
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ITEM_DETAIL],
        stale: true,
      });
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
    },
    onError: (error) => {},
  });

  const handleChangeUploadFile: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    if (!!newFileList?.length) {
      try {
        if (isUpload) {
          const listUrl = newFileList?.map((file: any) => {
            return {
              id: null,
              preview: (window.URL ? URL : webkitURL).createObjectURL(file?.originFileObj),
              source: file?.originFileObj,
            };
          });
          const uploads = fileUploads ? [...fileUploads, ...listUrl] : listUrl;
          setFileUploads(uploads);
          setFileList(newFileList);
          setIsUpload(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRemoveFile = (file: { preview: string; source: any }) => {
    setFileList(fileList?.filter((item) => item?.uid != file?.source?.uid));
    setFileUploads(fileUploads?.filter((item: any) => item !== file));
  };

  const onFinish = async (value) => {
    if (value?.photoQuantity) {
      if (
        +value?.photoQuantity !== fileUploads?.length &&
        activeAddPhoto?.takePhotoRequest?.status !== TAKE_PHOTO_REQUEST_STATUS.REQUESTED
      ) {
        NotificationError({ content: '写真が不足しています' });
        return;
      }
      let assetIds: any = [];
      if (!!fileUploads?.length) {
        const promiseUpload = fileUploads?.map((item) => {
          if (item?.id) return item?.id;
          return UploadFile.mutateAsync(item?.source).then((rs: any) => {
            return rs?.id;
          });
        });
        assetIds = await Promise.all([...promiseUpload]);
      }

      if (activeAddPhoto?.takePhotoRequest?.status === TAKE_PHOTO_REQUEST_STATUS.REQUESTED) {
        UpdateStorageProcessMutation.mutateAsync({
          photoRequestId: activeAddPhoto?.takePhotoRequest?.id,
          quantity: +value?.photoQuantity,
        });
      } else {
        UpdatePhotoRequestMutation.mutateAsync({
          assetIds: assetIds,
          storageItemId: activeAddPhoto?.takePhotoRequest?.storageItemId,
          photoRequestId: activeAddPhoto?.takePhotoRequest?.id,
        });
      }
    }
  };

  const handleTranslateMessage = () => {
    setIsShowMessageTranslate(!isShowMessageTranslate);
    if (!activeAddPhoto?.takePhotoRequest?.userMessageTranslated) {
      translateUserMessage();
    }
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldValue('photoQuantity', activeAddPhoto?.takePhotoRequest?.photoQuantity);

    if (!!(activeAddPhoto?.takePhotoRequest as any)?.photos?.length) {
      const photos = (activeAddPhoto?.takePhotoRequest as any)?.photos?.map((photo) => {
        return {
          id: photo?.id,
          preview: Helper.getSourceFile(photo?.preview),
          source: null,
        };
      });

      setFileUploads(photos);
    }
  }, []);

  const renderButtonTranslate = () => {
    return (
      <div className="item-detail_add-photo_body_note_translate_button">
        {isTranslating ? (
          <Spin
            style={{ position: 'unset', display: 'flex', height: 24, width: 24 }}
            spinning={isTranslating}
            indicator={<LoadingOutlined style={{ fontSize: 21 }} spin />}
          />
        ) : (
          <button type="button" onClick={handleTranslateMessage}>
            {isShowMessageTranslate ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.8175 10.071L6.165 12H4.5L7.293 4.5H9.219L12 12H10.2495L9.597 10.071H6.8175ZM9.2685 8.967L8.25 5.934H8.1765L7.158 8.967H9.2685Z"
                  fill="#1C86ED"
                />
                <path
                  d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L13.5 0C14.2956 0 15.0587 0.316071 15.6213 0.87868C16.1839 1.44129 16.5 2.20435 16.5 3V7.5H21C21.7956 7.5 22.5587 7.81607 23.1213 8.37868C23.6839 8.94129 24 9.70435 24 10.5V21C24 21.7956 23.6839 22.5587 23.1213 23.1213C22.5587 23.6839 21.7956 24 21 24H10.5C9.70435 24 8.94129 23.6839 8.37868 23.1213C7.81607 22.5587 7.5 21.7956 7.5 21V16.5H3C2.20435 16.5 1.44129 16.1839 0.87868 15.6213C0.316071 15.0587 0 14.2956 0 13.5V3ZM3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V13.5C1.5 13.8978 1.65804 14.2794 1.93934 14.5607C2.22064 14.842 2.60218 15 3 15H13.5C13.8978 15 14.2794 14.842 14.5607 14.5607C14.842 14.2794 15 13.8978 15 13.5V3C15 2.60218 14.842 2.22064 14.5607 1.93934C14.2794 1.65804 13.8978 1.5 13.5 1.5H3ZM13.707 16.4925C13.996 16.9435 14.311 17.3665 14.652 17.7615C13.53 18.624 12.1425 19.263 10.5 19.6995C10.767 20.025 11.1765 20.652 11.3325 21C13.02 20.4615 14.4525 19.734 15.6615 18.759C16.827 19.7565 18.27 20.5065 20.0565 20.967C20.256 20.586 20.6775 19.9575 21 19.632C19.3125 19.2525 17.9145 18.591 16.77 17.706C17.7915 16.5855 18.603 15.2295 19.2015 13.5705H21V12H16.5V13.5705H17.6475C17.1705 14.8365 16.5375 15.8895 15.7395 16.7655C15.5189 16.5308 15.3111 16.2845 15.117 16.0275C14.6934 16.2982 14.2085 16.4581 13.707 16.4925Z"
                  fill="#1C86ED"
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                  d="M6.8175 10.571L6.165 12.5H4.5L7.293 5H9.219L12 12.5H10.2495L9.597 10.571H6.8175ZM9.2685 9.467L8.25 6.434H8.1765L7.158 9.467H9.2685Z"
                  fill="#514B4D"
                />
                <path
                  d="M0 3.5C0 2.70435 0.316071 1.94129 0.87868 1.37868C1.44129 0.816071 2.20435 0.5 3 0.5L13.5 0.5C14.2956 0.5 15.0587 0.816071 15.6213 1.37868C16.1839 1.94129 16.5 2.70435 16.5 3.5V8H21C21.7956 8 22.5587 8.31607 23.1213 8.87868C23.6839 9.44129 24 10.2044 24 11V21.5C24 22.2956 23.6839 23.0587 23.1213 23.6213C22.5587 24.1839 21.7956 24.5 21 24.5H10.5C9.70435 24.5 8.94129 24.1839 8.37868 23.6213C7.81607 23.0587 7.5 22.2956 7.5 21.5V17H3C2.20435 17 1.44129 16.6839 0.87868 16.1213C0.316071 15.5587 0 14.7956 0 14V3.5ZM3 2C2.60218 2 2.22064 2.15804 1.93934 2.43934C1.65804 2.72064 1.5 3.10218 1.5 3.5V14C1.5 14.3978 1.65804 14.7794 1.93934 15.0607C2.22064 15.342 2.60218 15.5 3 15.5H13.5C13.8978 15.5 14.2794 15.342 14.5607 15.0607C14.842 14.7794 15 14.3978 15 14V3.5C15 3.10218 14.842 2.72064 14.5607 2.43934C14.2794 2.15804 13.8978 2 13.5 2H3ZM13.707 16.9925C13.996 17.4435 14.311 17.8665 14.652 18.2615C13.53 19.124 12.1425 19.763 10.5 20.1995C10.767 20.525 11.1765 21.152 11.3325 21.5C13.02 20.9615 14.4525 20.234 15.6615 19.259C16.827 20.2565 18.27 21.0065 20.0565 21.467C20.256 21.086 20.6775 20.4575 21 20.132C19.3125 19.7525 17.9145 19.091 16.77 18.206C17.7915 17.0855 18.603 15.7295 19.2015 14.0705H21V12.5H16.5V14.0705H17.6475C17.1705 15.3365 16.5375 16.3895 15.7395 17.2655C15.5189 17.0308 15.3111 16.7845 15.117 16.5275C14.6934 16.7982 14.2085 16.9581 13.707 16.9925Z"
                  fill="#514B4D"
                />
              </svg>
            )}
          </button>
        )}
      </div>
    );
  };

  return (
    <FormWrap form={form} onFinish={onFinish}>
      <div className={`item-detail_add-photo open`}>
        <div className="item-detail_add-photo_overlay" onClick={handleCloseAddPhoto}></div>
        <div className="item-detail_add-photo_body">
          <div className="item-detail_add-photo_body_top">
            <h6>{intl.formatMessage({ id: 'item.upload.modal.title' })}</h6>
            <span className={`item-detail_add-photo_body_top_${activeAddPhoto?.takePhotoRequest?.status}`}>
              {renderTextStatusPhotoRequest(activeAddPhoto?.takePhotoRequest?.status)}
            </span>
          </div>
          <div className="item-detail_add-photo_body_quantity">
            {/* <label htmlFor="数量">数量</label> */}
            <Form.Item
              label={
                <span className="color-sub-title font-size-16">
                  {intl.formatMessage({ id: 'item.upload.modal.quantity.label' })}
                </span>
              }
              className="m-b-0"
              name="photoQuantity"
              required={activeAddPhoto?.takePhotoRequest?.photoQuantity <= 0}
              rules={ValidateLibrary(intl).required}
            >
              <input
                className={activeAddPhoto?.takePhotoRequest?.photoQuantity <= 0 ? 'can-change' : ''}
                type="text"
                id="数量"
                readOnly={activeAddPhoto?.takePhotoRequest?.photoQuantity > 0}
                // value={activeAddPhoto?.takePhotoRequest?.photoQuantity}
              />
            </Form.Item>
          </div>
          <div className="item-detail_add-photo_body_note">
            <label htmlFor="依頼の説明">{intl.formatMessage({ id: 'item.upload.modal.note.label' })}</label>
            <textarea name="" id="依頼の説明" readOnly value={activeAddPhoto?.takePhotoRequest?.userMessage}></textarea>
            {renderButtonTranslate()}
            <div className="item-detail_add-photo_body_note_translate_message">
              {isShowMessageTranslate ? activeAddPhoto?.takePhotoRequest?.userMessageTranslated : ''}
            </div>
          </div>
          <div className="item-detail_add-photo_body_wrap">
            {activeAddPhoto?.takePhotoRequest?.status !== TAKE_PHOTO_REQUEST_STATUS.REQUESTED && (
              <h6 className="item-detail_add-photo_body_wrap_title">
                <p>{intl.formatMessage({ id: 'item.upload.upload-2' })}</p>
                <Upload
                  className="cursor-pointer"
                  multiple={true}
                  showUploadList={false}
                  onChange={handleChangeUploadFile}
                  fileList={fileList}
                  accept="image/*"
                  disabled={activeAddPhoto?.takePhotoRequest?.status === TAKE_PHOTO_REQUEST_STATUS.UPLOAD_BY_STAFF}
                >
                  <button type="button" className={`${activeAddPhoto?.takePhotoRequest?.status?.toLocaleLowerCase()}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M18 14V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V14M15.3333 9.33333L12 6M12 6L8.66667 9.33333M12 6V14"
                        stroke="#1C86ED"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>{intl.formatMessage({ id: 'item.upload.btn' })}</p>
                  </button>
                </Upload>
              </h6>
            )}
            {activeAddPhoto?.takePhotoRequest?.status === TAKE_PHOTO_REQUEST_STATUS.TAKE_BY_OPERATION && (
              <Form.Item name="file-upload" className="m-b-0 m-t-16">
                <Dragger
                  name="file"
                  multiple={true}
                  showUploadList={false}
                  onChange={handleChangeUploadFile}
                  beforeUpload={() => {
                    setIsUpload(true);
                    setFileList([]);
                  }}
                  fileList={fileList}
                  accept="image/*"
                >
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.33333 6.25C7.18274 6.25 6.25 7.18274 6.25 8.33333V31.6667C6.25 32.8173 7.18274 33.75 8.33333 33.75H31.6667C32.8173 33.75 33.75 32.8173 33.75 31.6667V8.33333C33.75 7.18274 32.8173 6.25 31.6667 6.25H8.33333ZM3.75 8.33333C3.75 5.80203 5.80203 3.75 8.33333 3.75H31.6667C34.198 3.75 36.25 5.80203 36.25 8.33333V31.6667C36.25 34.198 34.198 36.25 31.6667 36.25H8.33333C5.80203 36.25 3.75 34.198 3.75 31.6667V8.33333Z"
                        fill="#514B4D"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1665 12.916C13.4761 12.916 12.9165 13.4757 12.9165 14.166C12.9165 14.8564 13.4761 15.416 14.1665 15.416C14.8569 15.416 15.4165 14.8564 15.4165 14.166C15.4165 13.4757 14.8569 12.916 14.1665 12.916ZM10.4165 14.166C10.4165 12.0949 12.0954 10.416 14.1665 10.416C16.2376 10.416 17.9165 12.0949 17.9165 14.166C17.9165 16.2371 16.2376 17.916 14.1665 17.916C12.0954 17.916 10.4165 16.2371 10.4165 14.166Z"
                        fill="#514B4D"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M25.7829 15.7821C26.2711 15.294 27.0626 15.294 27.5507 15.7821L35.884 24.1155C36.3722 24.6036 36.3722 25.3951 35.884 25.8832C35.3959 26.3714 34.6044 26.3714 34.1163 25.8832L26.6668 18.4338L9.21738 35.8832C8.72922 36.3714 7.93777 36.3714 7.44961 35.8832C6.96146 35.3951 6.96146 34.6036 7.44961 34.1155L25.7829 15.7821Z"
                        fill="#514B4D"
                      />
                    </svg>
                    <p>画像をドロップ{intl.formatMessage({ id: 'item.upload.drag' })}</p>
                  </div>
                </Dragger>
              </Form.Item>
            )}
            <div className="item-detail_add-photo_body_list">
              {!!fileUploads?.length &&
                fileUploads.map((file) => {
                  return (
                    <div className="item-detail_add-photo_body_list_item">
                      <img src={file.preview} alt="" />
                      {activeAddPhoto?.takePhotoRequest?.status !== TAKE_PHOTO_REQUEST_STATUS.UPLOAD_BY_STAFF && (
                        <button onClick={() => handleRemoveFile(file)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="11"
                            viewBox="0 0 10 11"
                            fill="none"
                          >
                            <path
                              d="M9 1.5L1 9.5M1 1.5L9 9.5"
                              stroke="#514B4D"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="m-t-60"></div>
          {activeAddPhoto?.takePhotoRequest?.status !== TAKE_PHOTO_REQUEST_STATUS.UPLOAD_BY_STAFF && (
            <div className="item-detail_add-photo_body_button">
              <ButtonCancel
                value={intl.formatMessage({ id: 'chat-item.uploads.cancel' })}
                onClick={handleCloseAddPhoto}
              />
              <Spin
                spinning={
                  UpdatePhotoRequestMutation.isPending || UpdateStorageProcessMutation.isPending || UploadFile.isPending
                }
              >
                <ButtonAgree
                  value={intl.formatMessage({ id: `chat-item.uploads.${activeAddPhoto?.takePhotoRequest?.status}` })}
                />
              </Spin>
            </div>
          )}
        </div>
      </div>
    </FormWrap>
  );
};

export default TakePhotoModal;
