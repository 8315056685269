import React from 'react';

export const SvgWarningIcon = () => (
  <span>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 13.462C9.17467 13.462 9.32067 13.403 9.438 13.285C9.556 13.167 9.615 13.0207 9.615 12.846C9.615 12.672 9.556 12.526 9.438 12.408C9.32067 12.29 9.17467 12.231 9 12.231C8.82533 12.231 8.67933 12.29 8.562 12.408C8.44467 12.526 8.38567 12.672 8.385 12.846C8.38433 13.02 8.44333 13.1663 8.562 13.285C8.67933 13.403 8.82533 13.462 9 13.462ZM8.5 10.154H9.5V4.154H8.5V10.154ZM9.003 18C7.759 18 6.589 17.764 5.493 17.292C4.39767 16.8193 3.44467 16.178 2.634 15.368C1.82333 14.558 1.18167 13.606 0.709 12.512C0.236333 11.418 0 10.2483 0 9.003C0 7.75767 0.236 6.58767 0.708 5.493C1.18 4.39833 1.82133 3.44533 2.632 2.634C3.44267 1.82267 4.39467 1.181 5.488 0.709002C6.58133 0.237002 7.751 0.000668075 8.997 1.40845e-06C10.243 -0.000665258 11.413 0.235335 12.507 0.708001C13.601 1.18067 14.554 1.822 15.366 2.632C16.178 3.442 16.8197 4.394 17.291 5.488C17.7623 6.582 17.9987 7.75167 18 8.997C18.0013 10.2423 17.7653 11.4123 17.292 12.507C16.8187 13.6017 16.1773 14.5547 15.368 15.366C14.5587 16.1773 13.6067 16.819 12.512 17.291C11.4173 17.763 10.2477 17.9993 9.003 18Z"
        fill="#F43258"
      />
    </svg>
  </span>
);

export default SvgWarningIcon;
