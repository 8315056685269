import React from 'react';

export const SvgCoppy = () => (
  <span>
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2728 5.07227H6.56951C6.14265 5.07381 5.73388 5.2448 5.43307 5.54765C5.13225 5.8505 4.96401 6.26041 4.96534 6.68727V15.0939C4.96534 15.9856 5.68367 16.7089 6.56951 16.7089H13.2728C13.6997 16.7074 14.1085 16.5364 14.4093 16.2335C14.7101 15.9307 14.8783 15.5208 14.877 15.0939V6.68727C14.877 5.7956 14.1587 5.07227 13.2728 5.07227Z"
        stroke="#514B4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0352 5.07199V2.90699C11.0352 2.47866 10.866 2.06783 10.5652 1.76533C10.4167 1.61549 10.2401 1.49653 10.0454 1.41529C9.85077 1.33406 9.64196 1.29215 9.43104 1.29199H2.7277C2.3027 1.29199 1.89437 1.46199 1.59437 1.76533C1.29292 2.06884 1.12367 2.47922 1.12354 2.90699V11.3137C1.12354 11.742 1.2927 12.1528 1.59354 12.4553C1.89437 12.7587 2.30187 12.9287 2.7277 12.9287H4.9652"
        stroke="#514B4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default SvgCoppy;
