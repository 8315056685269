import React from 'react';

const SvgIncrease = () => (
  <span>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 18V16.2H16.2V13.5H18V16.65C18 17.01 17.82 17.28 17.55 17.55C17.28 17.82 16.92 18 16.65 18H13.5ZM4.5 18H1.35C0.99 18 0.72 17.82 0.45 17.55C0.18 17.28 0 16.92 0 16.65V13.5H1.8V16.2H4.5V18ZM13.5 0H16.65C17.01 0 17.28 0.18 17.55 0.45C17.82 0.72 18 0.99 18 1.35V4.5H16.2V1.8H13.5V0ZM4.5 0V1.8H1.8V4.5H0V1.35C0 0.99 0.18 0.72 0.45 0.45C0.72 0.18 0.99 0 1.35 0H4.5ZM15.3 8.1H2.7V9.9H15.3V8.1Z"
        fill="#514B4D"
      />
    </svg>
  </span>
);

export default SvgIncrease;
