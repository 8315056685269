export const SelectAssignSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1667 5.82353C12.1667 4.76747 11.7 3.81159 10.9467 3.12029C10.1925 2.42824 9.15083 2 8 2C6.84917 2 5.8075 2.42824 5.05333 3.12029C4.3 3.81159 3.83333 4.76747 3.83333 5.82353C3.83333 6.87959 4.3 7.83547 5.05333 8.52676C5.8075 9.21882 6.84917 9.64706 8 9.64706C9.15083 9.64706 10.1925 9.21882 10.9467 8.52676C11.334 8.17214 11.6412 7.75077 11.8506 7.28686C12.06 6.82295 12.1674 6.32565 12.1667 5.82353ZM3 13.4706C3 14.2353 4.875 15 8 15C10.9317 15 13 14.2353 13 13.4706C13 11.9412 11.0383 10.4118 8 10.4118C4.875 10.4118 3 11.9412 3 13.4706Z"
        fill="black"
      />
    </svg>
  );
};
