import { Button, ButtonProps } from 'antd';
import './index.scss';

const CustomeButton = (props: ButtonProps) => {
  const updateProps = { ...props };
  let buttonClassName = 'custom-ant-button';
  if (props.className) {
    buttonClassName = buttonClassName + ' ' + props.className;

    delete updateProps.className;
  }

  return <Button className={`custom-ant-button ${props.className}`} {...updateProps}></Button>;
};

export default CustomeButton;
