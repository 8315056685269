import './index.scss';
import { TButtonCustom } from './interface/interface';

const ButtonItemChatDone = ({ value, ...props }: TButtonCustom) => {
  return (
    <button className="button-repsponding" onClick={props.onClick}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 6.66732L8.172 8.29665C8.30656 8.39757 8.4743 8.44389 8.64158 8.42634C8.80886 8.40878 8.96332 8.32864 9.074 8.20198L13.3333 3.33398"
          stroke="#1C86ED"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M14 7.99989C14 9.25358 13.6073 10.4758 12.8771 11.4948C12.1469 12.5139 11.1158 13.2786 9.92861 13.6816C8.74146 14.0846 7.4579 14.1056 6.2582 13.7416C5.05851 13.3777 4.00295 12.6471 3.23978 11.6524C2.47661 10.6578 2.04418 9.4491 2.00321 8.19609C1.96223 6.94307 2.31479 5.7087 3.01135 4.66633C3.70791 3.62397 4.71349 2.82598 5.88684 2.38444C7.0602 1.9429 8.34239 1.87999 9.55333 2.20456"
          stroke="#1C86ED"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      <p>{value}</p>
    </button>
  );
};
export default ButtonItemChatDone;
