import { Pagination, Row, Spin, Table } from 'antd';
import './index.scss';
import { useEffect, useRef } from 'react';
import { ColumnType } from 'antd/es/table';

export interface IScrollTable {
  x?: number | string;
  y?: number | string;
}

export interface ITableWrapProps<T> {
  className?: string;
  data?: T[];
  isLoading?: boolean;
  page?: number;
  size?: number;
  total?: number;
  children?: any;
  rowKey?: string;
  setSize?: (size: number) => void;
  setPage?: (page: number) => void;
  isScroll?: boolean;
  scrollValue?: IScrollTable;
  // isShowPagination?: boolean;
  columns?: ColumnType<T>[];
}

function TableWrap<T extends object>(props: ITableWrapProps<T>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const tableBody = tableContainerRef.current?.querySelector('.ant-table-body');
      if (tableBody) {
        const { scrollTop, scrollHeight, clientHeight } = tableBody;

        if (
          scrollTop + clientHeight >= scrollHeight - 10 &&
          !props.isLoading &&
          props.page &&
          props.size &&
          props.total &&
          props.page * props.size < props.total
        ) {
          props.setPage?.(props.page + 1);
        }
      }
    };

    const tableBody = tableContainerRef.current?.querySelector('.ant-table-body');
    if (tableBody) {
      tableBody.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handleScroll);
      }
    };
  }, [props.isLoading, props.page, props.size, props.total, props.data]);

  return (
    <div ref={tableContainerRef}>
      <Table
        className={`ebay__scroll-bar-custom ant-custom-table ${props?.className ?? ''}`}
        dataSource={props.data}
        loading={props.isLoading}
        pagination={false}
        columns={props.columns}
        rowKey={props.rowKey || 'id'}
        rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
        scroll={props.isScroll ? props.scrollValue : {}}
      >
        {props.children}
      </Table>
    </div>
  );
}

export default TableWrap;
