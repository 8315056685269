export const TabIcon = () => {
  return (
    <span className="tab-icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1H17V3H21V12H19V5H17V7H7V5H5V21H12V23H3V3H7V1ZM9 5H15V3H9V5Z" fill="#FFB021" />
        <path
          d="M18.25 13L19.9225 16.24L23.5 16.75L20.875 19.1252L22 22.75L18.25 20.656L14.5 22.75L15.625 19.1252L13 16.75L16.675 16.24L18.25 13Z"
          fill="#FFB021"
        />
      </svg>
    </span>
  );
};
